const messages = {
  pageTitles: {
    login: 'Auth',
    join: 'Join workspace {workspace}',
    onBoarding: 'Select your working scopes',
    sprints: '{workspace} - Active sprints',
    messenger: '{workspace} - Messages',
    tasks: '{workspace} - Tasks',
    workload: '{workspace} - Workload',
    settings: {
      workspace: '{workspace} - Workspace settings',
      stages: '{workspace} - Stages settings',
      spheres: '{workspace} - Scopes settings',
      team: '{workspace} - Team management',
      profile: '{workspace} - Profile settings',
      notifications: '{workspace} - Notification settings',
    },
    setup: {
      general: 'Workspace creating',
      members: '{workspace} - Members',
      spheres: '{workspace} - Working scopes',
    }
  },
  tasks: {
    actions: {
      finish: 'Complete',
      start: 'Start task',
      returnToBacklog: 'Return to Backlog',
      returnToPreviousState: 'Return to the previous state',
      pause: 'Pause task',
      moveForward: 'Move to the next stage',
      deleteTask: 'Delete task'
    },
    deleteConfirmation: {
      title: 'Delete task',
      description: 'Are you really want to delete the task? After that, it will not be possible to restore it.',
      submit: 'Delete task',
      cancel: 'Return back',
      error: 'Could not delete the task, please try later'
    },
    widget: {
      remainTime: 'Remained hours for today',
      status: {
        open: 'Next',
        inProgress: 'In progress',
        onPause: 'Paused'
      },
    },
    offSchedule: {
      title: 'Working day is over',
      description: 'Time tracking is off now'
    },
    title: 'Tasks',
    allTasks: 'All Tasks',
    backlog: 'Backlog',
    searchPlaceholder: 'Search in tasks',
    createTask: 'Create task',
    newTask: 'New Task',
    newTaskBtn: 'Task',
    moveTaskToTheNextStage: 'Move task to the next stage',
    youCanSelectNextStageAndAssigneeForTask: 'You can select next stage and assignee for task.',
    stage: 'Stage',
    assignee: 'Assignee',
    comment: 'Comment',
    commentForNextAssignee: 'Comment for next assignee',
    finishTask: 'Finish task',
    notSelected: 'Not selected',
    returningTaskToAPreviousStage: 'Returning task to a previous stage',
    pleaseIndicateTheReasonForReturningTheTask: 'Please indicate the reason for returning the task, this will help the contractor to quickly identify and fix the problem.',
    description: 'Description',
    returningReasonDescription: 'Returning reason description',
    leaveAsIs: 'Leave as is',
    returnTask: 'Return task',
    anotherTaskInWork: 'Another task in work',
    youAlreadyHaveATaskInWork: 'You already have a task in work, in order to start working on a new one, you must first finish the previous one, or put it on pause',
    currentTask: 'Current task',
    isOpen: 'Is open',
    inProgress: 'In progress',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    enterTheComment: 'Enter the comment',
    commentWasSuccessfullyAdded: 'Comment was successfully added',
    started: 'Started',
    resumed: 'resumed',
    finished: 'Finished',
    paused: 'Paused',
    returned: 'Returned',
    planned: 'Planned',
    open: 'Open',
    completed: 'Completed',
    on: '',
    slower: 'Slower',
    faster: 'Faster',
    isOverdue: 'Overdue',
    overdueBy: 'Overdue by',
    notStarted: 'Not started',
    unknown: 'Unknown',
    unknownTime: 'Time unknown',
    from: 'from',
    returnedFrom: 'returned from',
    noScore: 'No score',
    thereAreNoTasksAvailableAtThisStage: 'There are no tasks available at this stage',
    emptyBacklog: 'No available tasks in backlog',
    emptyArchive: 'No tasks found',
    createNewTask: 'Create new task',
    newTaskName: 'New task name',
    enterTaskDescription: 'Enter task description',
    messages: 'Messages',
    attachments: 'Attachments',
    comments: 'Comments',
    addAttachment: 'Add attachment',
    noGoal: 'No goal',
    score: 'Score',
    took: 'Took',
    noSphere: 'No scope',
    search: {
      notFound: 'Tasks are not found'
    },
    numberIsCopied: 'Copied to clipboard',
    now: 'Now',
    archive: 'Completed Tasks',
    pause: 'Pause task',
    allMembers: 'All members',
    allSprints: 'All sprints',
    allGoals: 'All goals',
    membersCount: 'Members [{count}]',
    sprintsCount: 'Sprints [{count}]',
    goalsCount: 'Targets [{count}]',
    tasksCount: 'Tasks [{count}]',
    statuses: {
      open: 'On queue',
      in_progress: 'In progress',
      finished: 'Finished',
      paused: 'On pause',
      overdueBy: 'Overdue by {date}',
      overdueByItem: 'overdue by {date}',
      overdue: 'In progress',
      not_scored: 'On queue',
      unknownFinish: 'End time unknown'
    },
    points: 'Complexity points',
    pointsLabel: '{points} point | {points} points',
    pointsEmpty: 'Not set',
    created: 'created {date}',
    progress: {
      startedAt: 'Started at',
      finishedAt: 'Finished at',
      beProcessedAt: 'Be processed at',
      estimatedFinishAt: 'Estimated finish at',
      spentTime: 'Time at work',
      estimatedFinishAtUnknown: 'Remaining time is unknown',
      stageBreakdown: 'Work breakdown by stage',
      noScore: 'Cannot build estimation',
      noScoreShort: 'No data',
      overdueBy: 'Task is overdue by'
    },
    scoring: {
      hour: 'h.',
      minutes: 'min.',
      day: '{days} day | {days} days'
    },

    errors: {
      notFound: {
        title: 'Oops... task not found',
        text: 'Could not find requested task, maybe it has been deleted. Try to find out the task on the list page.',
      }
    },
    noAssignee: 'No assignee',
  },

  goals: {
    title: 'Goals',
    newGoal: 'New Goal',
    newGoalBtn: 'Goal',
    goalWasSuccessfullyClosed: 'Goal was successfully closed',
    goalClosed: 'Goal completed',
    showTasks: 'Show tasks',
    noTasks: 'No tasks related to the goal',
    progress: 'Progress',
    noEstimatedData: 'No data',
    tasks: 'tasks',
    outOf: 'out of',
    finished: 'finished',
    plannedCompletion: 'Due date',
    assignees: 'Assignees',
    editGoal: 'Edit goal',
    goalNameIsRequired: 'Goal name is required',
    descriptionOfTheTargetTasksAndBenefit: 'Describe your goal, it\'s benefits and what results are you going to reach out',
    goalName: 'Название таргета',
    closeTheGoal: 'Complete the goal',
    stayInWork: 'Stay in work',
    thereAreStillUnfinishedTasksInWorkForThisGoal: 'There are still unfinished tasks in work for this goal. Do you want to close the goal anyway?',
    noGoals: {
      title: 'Set the new goal',
      feature: 'Set new goals and combine tasks into them. It will make workflow is controlled and predictable, that allows to reach results faster.',
      feature1: 'Create a goal and add related tasks to reach it.',
      feature2: 'Having goals provide a clear understanding how many tasks left to complete it and how much time it requires.'
    },
    createTitle: 'Create new goal',
    updateTitle: 'Edit goal',
    form: {
      name: 'Name',
      color: 'Color',
      descriptionPlaceholder: 'Goal description',
      errors: {
        requiredName: 'Goal must be named'
      },
      btnUpdate: 'Edit goal',
      btnCreate: 'Create goal',
      createdTitle: 'Goal created',
      updatedTitle: 'Goal updated'
    },
    activeMany: 'Active',
    finishedMany: 'Finished'
  },

  comments: {
    deleted: 'Comment has been deleted',
    deleteTitle: 'Delete comment',
    deleteDescription: 'Are you sure you want to delete the comment?',
    updatedAt: 'Edited ',
    error: {
      updateTitle: 'Error occurred while comment deleting, please try again'
    }
  },

  members: {
    schedule: 'Work schedule',
    workNow: 'Working now',
    you: 'you',
    assignTask: 'Assign new task',
    sendMessage: 'Send message',
    roles: {
      owner: 'Owner',
      admin: 'Admin',
      member: 'Member',
      invited: 'Invited'
    },
    hourlyRate: 'Hourly rate',
    emptyHourlyRate: 'Not defined',
  },

  auth: {
    inviteToJoin: 'is inviting you to join {workspace}',
    join: 'Join workspace',
    singInToAccount: 'Sing in',
    singIn: 'Sign into workspace',
    invitation: 'Invitation',
    enterYourEmail: 'Email',
    enterYourPassword: 'Password',
    enterYourPasswordAgain: 'Enter your password again',
    yourFirstName: 'First name',
    yourLastName: 'Last name',
    emailIsRequired: 'Email is required',
    firstNameIsRequired: 'First name is required',
    lastNameIsRequired: 'Last name is required',
    passwordIsRequired: 'Password is required',
    passwordMustBeAtLeast6Characters: 'Password must be at least 6 characters',
    enterConfirmation: 'Enter confirmation',
    confirmationDoesNotMatch: 'Confirmation does not match',
    createAccount: 'Create an account',
    welcomeBack: 'Welcome back to Scorework! 👋',
    loginHint: 'Enter your email and password to log in.',
    forgetPassword: 'Forgot password?',
    joinSuccessTitle: 'Congratulations, you have successfully joined <br/>{workspace}! 🎉',
    declined: 'You have declined to join the workspace.',
    failedToUpdateInviteStatus: 'Failed to update invite status',
  },

  onboarding: {
    spheres: {
      description: 'Please set your working scopes, it helps to manage workflow.'
    }
  },

  common: {
    close: 'Close',
    create: 'Create',
    send: 'Send',
    proceed: 'Proceed',
    status: 'Status',
    cancel: 'Cancel',
    added: 'Added',
    add: 'Add',
    save: 'Save',
    saved: 'Saved',
    accept: 'Accept',
    delete: 'Delete',
    decline: 'Decline',
    select: 'Select',
    error: 'Error',
    clear: 'Clear',
    edit: 'Edit',
    success: 'Success',
    notEnoughPermissions: 'Not enough permissions.',
    closeWindow: 'Close window',
    changesSaved: 'Changes saved',
  },

  workspaces: {
    baseSettings: 'Base settings',
    workspaceSettingsSaved: 'Workspace settings saved'
  },

  validation: {
    required: 'Value is required',
    integer: 'Value must be integer',
    minScoreValue: 'The minimum value allowed is {minutes}',
    maxScoreValue: 'Value must be less than {hours} hours',
    emptyTaskTitle: 'Please input task title',
  },

  teams: {
    noMembers: 'No members'
  },

  archive: {
    targetName: 'Target name',
    sprintName: 'Sprint name',
    taskName: 'Task name',
    AssigneeName: 'Assignee name',
  },

  sprints: {
    fact: 'Actual',
    planned: 'Planned',
    remainingTasks: 'Remaining tasks',
    statisticsByMembers: 'Statistics by members',
    displayingMembers: 'Displaying members',
    progressOfWork: 'Progress of work',
    unaccountedTime: 'Unaccounted time',
    min: 'min',
    h: 'h',
    timeOfTasksInWork: 'Time of tasks in work',
    efficiency: 'Efficiency',
    completedByTasks: 'Completed by tasks',
    graph: {
      today: 'Now',
      endOfSprint: 'Sprint end'
    },
    sprintIsNotConfigured: 'Sprint is not configured',
    sprintNumber: 'Sprint №{number}',
    sprintSettingsUpdate: 'Update sprint settings',
    sprintSettingsSaved: 'Sprint settings saved',
    noSprintsShort: 'No sprints',
    noSprints: 'No active sprints',
    noSprintsText: 'Create first issues to get the sprint automatically built.',
    goToTasks: 'Go to issues',
  },

  pages: {
    clearTasks: 'Clear tasks',
    mySpheres: 'My spheres',
    setYourSpheres: 'Set your spheres',
    noSpheresSelected: 'No spheres selected',
    workingShift: 'Work schedule',
    profileSaved: 'Your profile saved',
    incorrectLoginOrPassword: 'Incorrect login or password',
    editProfile: 'Edit profile'
  },

  settings: {
    menu: {
      workspace: {
        title: 'Workspace',
        settings: 'Settings',
        rate: 'Subscription plan',
        team: 'All users',
      },
      project: {
        title: 'Project',
        spheres: 'Spheres',
        stages: 'Stages',
        team: 'Team',
        settings: 'Settings',
      },
      profile: {
        title: 'Profile',
        settings: 'Settings',
        notifications: 'Notifications',
      }
    },
    projectMembers: 'Project team',
    project: {
      title: 'Project settings',
      notifications: {
        logo: 'Project logo updated'
      }
    },
    user: {
      personal: 'Personal settings',
      projectSettings: 'Project preferences',
    },
    general: {
      title: 'Workspace settings',
      workspaceName: 'Name',
      taskPrefix: 'Task number prefix',
      workSchedule: 'Work schedule',
      currency: 'Currency of payments to members',
      considerHolidays: 'Consider national holidays',
      country: 'Selected country',
      sprintDuration: 'Sprint duration',
      notifications: {
        logo: 'Workspace logo updated'
      }
    },

    rate: {
      title: 'Rate plan',
      currentPlan: 'Current plan',
      plan: 'Plan',
      activatedAt: 'Activated at',
      expiredAt: 'Expired at',
      membersCount: 'Active users',
      unlimitedRounded: '(unlimited)',
      unlimited: 'unlimited',
      ofCapacity: 'of {count}',
      changePlan: 'Change rate plan',
      upgrade: {
        title: 'It is time to switch to senior plan',
        description: `<p>Workspace has exceeded {feature} limit for the current plan "{plan}".</p>
        <p>Choose the new plan for you team and continue to work together.</p>`,
        submit: 'Choose plan',
        cancel: 'Got it',
        features: {
          projects_count: 'projects',
          members_count: 'users',
          file_storage_size: 'file storage size',
          pages_count: 'maximum amount of pages in knowledge base'
        },
      },
      ratePlans: {
        free: 'Free',
        flex: 'Flex',
        start: 'Start',
        premium: 'Premium',
        corporate: 'Corporate'
      },
      priceTypes: {
        per_member_month: 'user/month',
        monthly: 'monthly',
        annual: 'annual'
      },

      badge: {
        title: 'Plan "{ratePlan}"',
        trialDaysRemain: '{days} days remain | {days} days remain | {days} days remain | {days} days remain',
        trial: 'Trial period',
        trialBadge: 'trial',
        goToPay: 'Go to pay',
        freeText: '<p>Expand your options by going to senior rate plan!</p><p>Any rate plan includes <b>free 30 days</b> trial period.</p>',
        membersLimit: 'up to {count} members | up to {count} members | up to {count} members | up to {count} members',
        dialog: {
          title: '🎉 Payment request is accepted',
          description: '<p>Your application for payment has been successfully accepted.</p> <p>Our manager will reach you out soon and you are able to complete payment via your personal section on the website.</p> <p>All features of current rate are still provided free.</p>',
        }
      }
    },
    members: {
      addMember: 'Add member',
      sendInvitations: 'Send invitations',
      addedMembers: 'Added members',
      selected: 'Selected members:',
      selectedProjects: 'Selected projects:',
      projectsLabel: 'Select projects',
      membersLabel: 'Select members',
      projectsError: 'Select at least 1 project',
      availableNewMembersTitle: 'You can invite',
      availableNewMembersCount: '{count} new user | {count} new users',
      enterEmail: 'Enter member email',
      noUsers: 'Users not found',
      emailError: 'Please fill valid email',
      inviteMembersTitle: 'Invite new members',
      successTitle: 'Members invited',
      successDescription: 'Invitations successfully sent',
      tabs: {
        active: 'Active members',
        invited: 'Invitations',
      },
      hourlyRateLabel: 'Fill hourly rate',
      noInvitedMembers: 'No invited members',
      notifications: {
        spheres: 'Member spheres updated',
        role: 'Member role changed',
        hourlyRate: 'Member hourly rate changed',
        inactive: 'Member removed from workspace',
        failed: 'Could not save changes',
      }
    },

    notifications: {
      title: 'Notification settings',
      description: 'You can choose what kind of notifications about task changing will be sent to your email.',
      options: {
        task: {
          assigned: 'Task has been assigned to you',
          comment_created: 'New comment added to your task',
          idle: 'Task is waiting to be processed',
          overdue: 'Task deadline is overdue',
          stage_changed: 'Task has been moved to new stage',
          taken: 'Task has been taken at work',
          finished: 'Task has been completed',
        }
      },
      saveBtn: 'Save changes',
      notify: {
        success: 'Settings updated',
        failed: 'Could not update settings',
      }
    },
    memberInvite: 'Member invite',
    memberReinviteSent: 'Reinvite email sent',
    memberReinviteSentFailed: 'Failed to sent reinvite email',
    team: 'Team',
    role: 'Role',
    actions: 'Actions',
    excluded: 'Excluded',
    removeFromTeam: 'Remove from team',
    resendMemberInvite: 'Resend invite',
    invited: 'Invited',
    inviteMembers: 'Invite members',
    editStage: 'Edit stage',
    addChildSphere: 'Add child sphere',
    noMembersAndChildSpheres: 'No members and child spheres',
    upload: 'Upload',
    nameIsRequired: 'Name is required',
    projectName: 'Project name',
    taskPrefix: 'Task prefix',
    sphereName: 'Sphere name',
    profile: {
      language: 'Interface language'
    }
  },

  stages: {
    description: `Configure tasks life cycle in order to your workflow.<p>You can set up default order of passing stages for all tasks, as well as customize sequence for tasks related to particular sphere.</p>`,
    addStages: 'Add stages for certain sphere',
    addStage: 'Add stage',
    defaultSphere: 'Default',
    newStagesDescription: 'Customize stage sequence for each sphere particularly.',
    initialStageHint: 'This is the first stage for any tasks being processed.</br>It is base stage for any tasks and it cannot be deleted,</br> but can be renamed.',
    finishedHint: 'It is final stage for any tasks. </br>Cannot be edited or deleted.',
    stageAdded: 'Stage added',
    newStage: 'New stage',
    editStage: 'Edit stage',
    doNotNotifyAnyone: 'Do not notify anyone',
    membersToBeNotifiedOfNewTasks: 'Members to be notified of new tasks',
    taskScoreByDefaultInMinutes: 'Task score by default in minutes',
    timeTrackingOfTasks: 'Time tracking of tasks',
    stageName: 'Stage name',
    sphereIsRequired: 'Sphere is required',
    selectSphere: 'Select sphere',
    sphere: 'Sphere',
    stageDeleted: 'Stage deleted',
    completed: 'Completed',
    deleteAndMove: 'Delete and move',
    selectStage: 'Select stage',
    selectStageToMoveTasksFrom: 'Select stage to move tasks from',
    deleteStage: 'Delete stage',
    workspaceStages: 'Workspace stages'
  },

  setup: {
    hints: {
      tasks: {
        name: {
          title: 'Workspace',
          content: 'Task flow in workspace is inseparable chain, where each element affects on completion dates of next items'
        },
        taskPrefix: {
          title: 'Task Number Prefix',
          content: 'Add a shortname as prefix to your task numbers'
        },
        schedule: {
          title: 'Work Schedule',
          content: `Setting up work hours gives to the system build more accurate estimations based on your schedule.
            <p>We suggest to turn on considering national holidays.</p>
`
        },
        spheres: {
          title: 'Scopes',
          content: `Separation of tasks and members by scopes makes workflow management is more simple. 
You are able to to build a custom workflow for each scope.`
        },
      },
      targets: {
        title: 'Goals',
        content: `Set the goals and combine tasks into them. It allows you predict the time to reach your goals, keep current progress posted and make changes in advance in case of deviation on original plan.`
      },
      spheres: {
        allSpheres: 'All scopes',
        tasks: {
          title: 'Distribute and filter tasks by scopes',
          content: `Keep under control each field of work.`
        },
        members: {
          title: 'Scopes - is area of responsibility of each member',
          content: `All members can be distributed by scopes, thereby indicating their area of responsibility.`
        }
      }
    },
    samples: {
      tasks: {
        name1: 'Implement new mockup for checkout page',
        name2: 'Complete widget for active task',
        name3: 'Update authentication form',
        sphere1: 'Development',
        sphere2: 'Frontend'
      },

      targets: {
        name1: 'Launch referral program'
      }
    },
    general: {
      title: 'Create new workspace',
      submit: 'Create workspace'
    },
    members: {
      title: 'Add team members',
      cancel: 'Invite other members later'
    },
    spheres: {
      title: 'Set workspace scopes',
      description: `Proposed list of scopes is suitable for the teams engaged in WEB or mobile development.
<p>You are able to customize scope structure for your needs.</p>`,
      skipText: 'Skip it now and set scopes later',
      defaultList: {
        development: 'Development',
        backend: 'Backend',
        frontend: 'Frontend',
        design: 'Design'
      }
    },
    addNestedSphere: 'Add nested scope',
    newSphere: 'New scope',
    skipAndGoToWorkspace: 'Skip and go to workspace',
    addWorkspaceMembers: 'Add workspace members',
  },

  spheres: {
    title: 'Scopes',
    workspaceSpheres: 'Workspace scopes',
    addSphereStages: 'Add scope stages',
    spheresSavedSuccessfully: 'Scopes updated successfully',
    addSphere: 'Add scope'
  },

  notifications: {
    markAsRead: 'Mark as read',
    assignedATaskToYou: 'assigned a task to you',
    takenTheTask: 'taken the task',
    finishedTheTask: 'finished the task',
    movedTaskToStage: 'moved task to stage',
    addedCommentToTask: 'added comment to task',
    changedATaskScore: 'changed a task score',
    taskOverdue: 'Task exceeded planned time frame'
  },

  messenger: {
    messages: 'Messages',
    chooseChatMembers: 'Choose chat members',
    searchChatMember: 'Search member',
    goToChat: 'Go to chat',
    newChat: 'New chat',
    createNewChat: 'Create new dialog',
    noChannels: 'No active dialogs yet',
    startNewDialog: 'Start new dialog',
    indexDescription: 'All your conversations with other participants will always be in quick access and at a single place.',
    isTyping: 'is typing...',
    areTyping: 'are typing...',
    unreadMessages: 'Unread messages',
    dialogs: 'Dialogs',
    helpdesk: 'Support',
    you: 'You',
    helpdeskTitle: 'Scorework. Support',
    noLastMessage: 'No messages yet'
  },

  workload: {
    noTasks: 'No tasks',
    createNewTask: 'New task',
    tasksCount: '{tasks} tasks | {tasks} task',
    tasksUntil: 'workload until',
    sortOptions: {
      tasksCount_desc: 'Most tasked members first',
      tasksCount_asc: 'Less tasked members first',
      estimatedFinishAt_desc: 'Most work loaded members first',
      estimatedFinishAt_asc: 'Less work loaded members first',
    },
    filters: {
      allSpheres: 'All spheres',
      spheresCount: '{count} sphere selected | {count} spheres selected',
      selectedSpheres: 'Displayed spheres:',
    }
  },

  goalsProgress: {
    displayOptions: {
      showAll: 'All tasks',
      bySpheres: 'Tasks by spheres',
    },
    eye: {
      showAll: 'Show all spheres',
      showActive: 'Hide spheres which has no tasks',
    },
    estimatedAt: 'Estimated time to complete',
    noTasksCreated: 'No tasks added to the goal yet',
    allTasksCompleted: 'All tasks to complete the goal have been completed',
    sort: {
      tasks_desc: 'With higher count of opened tasks first',
      tasks_asc: 'More closer to completion first'
    }
  },

  taskStats: {
    suggestedScore: 'Suggested score',
    memberNotSelected: 'Assignee is not set',
    prediction: {
      averageSpentTime: 'Average execution time',
      scoreRecommendation: 'Score suggestions',
      notEnoughData: 'There is not enough data to display statistics for completed tasks.',
      emptyCriteriaTitle: 'It is recommended to complete more fields to get more accurate score:',
      memberField: 'Assignee',
      sphereField: 'Sphere',
      pointsField: 'Task complexity points',
      spentTimeByTeam: 'among the team',
      spentTimeByMember: 'by assignee',
      taskStatsLink: 'Tasks statistics'
    }
  },


  layout: {
    inProcess: 'In process',
    complete: 'Complete',
    settings: 'Settings',
    spheres: 'Spheres',
    stages: 'Stages',
    members: 'Members',
    teams: 'Teams',
    dashboard: 'Dashboard',
    tasks: 'Tasks',
    workload: 'Workload',
    goals: 'Goals overview',
    pages: 'Knowledge base',
    reports: 'Reports',
    sprints: 'Sprints',
    messenger: 'Messages',
    profile: 'Profile',
    logout: 'Logout',
    notifications: 'Notifications'
  },

  ui: {
    noUsersFound: 'No users found',
    leave: 'Leave',
    nothingFound: 'Nothing found',
    enterName: 'Enter name',
    monday: 'Monday',
    mondayTime: 'Monday at {time}',
    mo: 'Mo',
    tuesday: 'Tuesday',
    tuesdayTime: 'Tuesday at {time}',
    tu: 'Tu',
    wednesday: 'Wednesday',
    wednesdayTime: 'Wednesday at {time}',
    we: 'We',
    thursday: 'Thursday',
    thursdayTime: 'Thursday at {time}',
    th: 'Th',
    friday: 'Friday',
    fridayTime: 'Friday at {time}',
    fr: 'Fr',
    saturday: 'Saturday',
    saturdayTime: 'Saturday at {time}',
    sa: 'Sa',
    sunday: 'Sunday',
    sundayTime: 'Sunday at {time}',
    su: 'Su',
    today: 'Today',
    yesterday: 'Yesterday',
    gallery: {
      title: 'Image preview'
    }
  },

  user: {
    beforeStartingWorkSpecifyYourSpheres: 'Before starting work, specify your spheres, this will help in managing the workflow.',
    indicateYourSpheres: 'Indicate your spheres',
    noSpheresSelected: 'No working scopes selected',
    skipAndGoToTasks: 'Skip and go to tasks',
  },

  workSchedule: {
    timeFrom: 'from',
    timeTo: 'to'
  },

  dateTime: {
    hour: 'hour | hours',
    minute: 'minute | minutes',
    week: 'week | weeks',
    months: {
      January: 'january',
      February: 'february',
      March: 'march',
      April: 'april',
      May: 'may',
      June: 'june',
      July: 'july',
      August: 'august',
      September: 'september',
      October: 'october',
      November: 'november',
      December: 'december'
    },
    lessThenAnHour: 'Less than an hour',
    tomorrowAt: 'Tomorrow',
    todayAt: 'Today',
    yesterdayAt: 'Yesterday at',
    ago: 'ago',
    just: 'just',
    after: 'In',
    today: 'Today',
    yesterday: 'Yesterday',
    short: {
      hours: 'h',
      minutes: 'min',
      days: 'day',
    },

  },

  countries: {
    RU: 'Russia',
    US: 'United States',
    CA: 'Canada',
    UA: 'Ukraine',
    KZ: 'Kazakhstan',
    BY: 'Belarus',
    EE: 'Estonia',
    LV: 'Latvia',
    UK: 'United Kingdom',
    AU: 'Australia',
    notSelected: 'Not selected'
  },


  tour: {
    skip: 'Skip tour',
    prevStep: 'Back',
    nextStep: 'Got it!',
    complete: 'Complete',

    fakeTasks: {
      flowName1: 'Getting know with main Scorework functionality',
      flowName2: 'Invite another team members to workspace',
      flowName3: 'Set new goals and create tasks to reach them',
      flowName4: 'Configure spheres and stages',
      backlog1: 'Complete first sprint and track team performance',
      backlog2: 'Move tasks from backlog to task flow',
    },

    fakeTargets: {
      name1: 'Configure workflow in the new workspace',
    },

    started: {
      title: 'Workspace is ready to go',
      description: '<p>But we firstly recommend to familiarize yourself with the basic system controls and features.</p>'
        + '<p>It will take no more than 5 minutes, but save much more time further.</p>',
      confirm: 'Start tour',
      skip: 'Skip tour'
    },

    completed: {
      title: 'Now you are definitely ready :)',
      description: '<p>Thank you for familiarizing yourself with the basic functionality of the system!</p>' +
        '<p>Please rate how useful this tour was for you?</p>',
      confirm: 'Get started',
      emojiError: 'Please select emoji according to your impression',
      feedback: {
        worst: 'Sucks',
        bad: 'So-so',
        ok: 'ok',
        good: 'I like it',
        perfect: 'Awesome!'
      },
      feedbackPlaceholder: 'Your comment (optional)'
    },

    steps: {
      spheres: {
        title: 'Getting to know the spheres',
        description: `<p>Spheres - these are key skills of your team, for example: development, design, marketing, etc.</p>
          <p>Combine team members and tasks to spheres and track progress by each direction.</p>`,
      },

      members: {
        title: 'Your team',
        description: 'Quick access to all team members: <ul>' +
          '<li>filter tasks by assignee;</li>' +
          '<li>assign new task to any member;</li>' +
          '<li>start chat;</li>' +
          '<li>or invite new members.</li></ul>'
      },

      stages: {
        title: 'Task stages',
        description: `<p>Always be keeping posted what stage are active tasks on.</p> 
          <p>The order of passing the stages goes sequentially from left to right. In the settings you can set your own sequence for each sphere separately.</p>`,
      },

      tasksFlow: {
        title: 'Dynamic task pipeline',
        description: '<p>All tasks come as continuous flow like pipeline and executed sequentially from top to bottom.</p>' +
          '<p>The system builds work plan itself based on task estimations and current progress. Any changes in tasks automatically affect on current prognosis and rebuild it.</p>' +
          '<p>Drag and drop task tickets within workarea to change execution order. Time estimates will be recalculated automatically.</p>'
      },

      tasksDate: {
        title: 'Task statuses',
        inProgress: 'Task in progress now',
        overdue: 'Task in progress and overdue',
        open: 'Task in queue',
        paused: 'Task on pause now, but has already started',
      },

      tasksProgress: {
        title: 'Progress bar',
        description: `<p>Always be keeping posted what stage are active tasks on.</p>`
      },

      activeTask: {
        title: 'Active task widget',
        description: '<p>Your active task always in quick access, control it and change status using special widget.</p><p>Widget will be displayed on the left sidebar when  at least one task is assigned to you.</p>'
      },

      targets: {
        title: 'Set goals and track the progress',
        description: '<p>Track how task by task your goals are moving to their completion.</p>'
      },

      backlog: {
        title: 'Task backlog',
        description: '<p>Create tasks backlog then move them to dynamic pipeline.</p>'
      }
    }
  },

  currencies: {
    RUB: 'Russian ruble',
    USD: 'United States dollar',
    EUR: 'Euro',
    GBP: 'Great Britain pounds',
  },

  feed: {
    noItems: 'There are no updates yet',
    types: {
      task: {
        created: 'Task created',
        assigned: 'Task assigned',
        score_changed: 'Time estimation changed',
        stage_changed: 'Task moved to stage',
        file_attached: 'File attached',
        comment: {
          added: 'Comment added',
          mentioned: 'Has been mentioned in comment',
        },
        progress: {
          started: 'Task has been taken at work',
          paused: 'Task paused',
          overdue: 'Task is overdue',
          completed: 'Task completed',
        }
      }
    }
  },

  dashboard: {
    sprint: {
      overallProgress: 'Sprint progress',
      memberProgress: 'Your progress',
    },

    titles: {
      team: 'Team',
      sprint: 'Current sprint',
      feed: 'Last changes',
      upcomingTasks: 'Upcoming tasks',
      activeTask: 'Your current task',
    },

    tasks: {
      emptyTasks: 'There are no tasks assigned to you now.',
      noUpcomingTasks: 'You have not got upcoming tasks yet.',
      showOthers: 'Show next tasks',
      hideOthers: 'Hide full list',
    }
  },

  projects: {
    title: 'Projects',
    createBtn: 'Create project',
    form: {
      name: 'Project name',
    }
  },

  workspaceMenu: {
    profile: 'Profile',
    workspace: 'Workspace',
    memberProjects: 'Your projects',
    projectMemberCount: '{count} member | {count} members',
  },

  report: {
    title: 'Reports',
    beta: 'Work on this section still in progress',
    download: 'Download report',
    header: {
      member: 'Assignee',
      tasks: 'Tasks',
      totalTasks: 'Total',
      completedTasks: 'Completed',
      expectedTasks: 'Expected',
      createdTasks: 'Created',
      delta: 'Difference',
      time: 'Working time, hours',
      totalScore: 'Spent time',
      expectedScore: 'Expected to spend',
      points: 'Tasks complexity',
      totalPoints: 'Overall',
      avgPoints: 'Average',
    },
    total: 'Total',
    emptyTitle: 'No enough data to build reports',
    emptyText: 'Start to create first tasks and they will be automatically placed into the sprint <br/>and reports is built',
    selectSprint: 'Select sprints',
    notSelectedSprint: 'Not selected',
  },

  editor: {
    table: {
      columns: 'Columns',
      rows: 'Rows',
      addColumnBefore: 'Add column before',
      addColumnAfter: 'Add column after',
      deleteColumn: 'Remove column',
      addRowBefore: 'Add row before',
      addRowAfter: 'Add row after',
      deleteRow: 'Remove row',
      deleteTable: 'Remove table',
    }
  },

  knowledge: {
    title: 'Knowledge base',
    noPages: 'No pages',
    commonPages: 'Common pages',
    createPage: 'Create new page',
    publishPage: 'Publish page',
    lastUpdates: 'Recently updated pages',
    notifications: {
      published: 'Page published',
      publishedMore: 'Now the page is available for other team',
      publishedFailed: 'Could not publish the page, please try again',
      fillPageName: 'Enter the page name',
    },
    emptySection: 'This section has no any pages now',
  }
}

export default messages
