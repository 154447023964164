<template>
  <el-dialog
    :title="$t('goals.closeTheGoal')"
    :visible="true"
    width="40%"
    :close-on-click-modal="false"
    @close="onClose"
  >
    <div>
      <div class="common-desc mb-4">
        {{ $t('goals.thereAreStillUnfinishedTasksInWorkForThisGoal') }}
      </div>

      <div class="fx fx_end mt-4">
        <ui-button @click.prevent="onClose" class="btn-light-grey mr-2" ui-class="size_md">{{ $t('goals.stayInWork') }}</ui-button>
        <ui-button @click.prevent="onSubmit" ui-class="size_md">{{ $t('goals.closeTheGoal') }}</ui-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UiButton from 'scorework-ui/src/components/UiButton'

export default {
  name: 'ConfirmFinishTargetModal',

  components: { UiButton },

  props: {
    target: {
      type: Object,
      required: true
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onSubmit () {
      this.$emit('submit', this.target)
    }
  }
}
</script>
