const projectSocket = (store) => socket => {
  const listeners = {
    'connect': () => {
      const { member_id: memberId } = store.rootGetters.authUser
      const { global_id: workspaceId } = store.rootGetters.workspace
      const { code: projectCode } = store.rootGetters.project

      socket.emit('initProject', { workspaceId, projectCode, memberId })
    },

    'workspace.members.online': ({ memberIds }) => {
      store.dispatch('setMembersOnline', { memberIds })
    },

    'workspace.members.in': ({ memberId }) => {
      store.dispatch('setMemberOnline', { memberId, isOnline: true })
    },

    'workspace.members.out': ({ memberId }) => {
      store.dispatch('setMemberOnline', { memberId, isOnline: false })

      const { workspace, project, authUser } = store.rootGetters
      if (authUser.member_id === memberId) {
        socket.emit('project.member.in', {
          workspaceId: workspace.global_id,
          projectCode: project.code,
          memberId,
        })
      }
    },

    'members.created': ({ member }) => {
      store.dispatch('addMember', member)
    },

    'members.updated': ({ member }) => {
      store.dispatch('setProjectMember', member)
    },

    'members.deleted': ({ member }) => {
      store.dispatch('setRemovedMember', member)
    },

    'project.members.added': ({ member }) => {
      store.dispatch('setProjectMember', member)
    },

    'project.members.updated': ({ member }) => {
      store.dispatch('setProjectMember', member)
    },

    'projects.updated': ({ project }) => {
      store.dispatch('refreshProjectData', { project })
    },

    'stages.updated': ({ stage }) => {
      store.dispatch('refreshStage', { stage })
    },

    'stages.created': ({ stage }) => {
      store.dispatch('addStage', stage)
    },

    'stages.deleted': ({ stage }) => {
      store.dispatch('deleteStage', stage)
    },

    'stages.sort.updated': ({ stages }) => {
      store.dispatch('refreshStages', stages)
    },

    'targets.updated': ({ target }) => {
      store.dispatch('refreshTarget', { target })
    },

    'targets.deleted': ({ target_id }) => {
      store.dispatch('refreshDeletedTarget', { targetId: target_id })
    },

    'targets.created': ({ target }) => {
      store.dispatch('appendTarget', { target })
    },
  }

  Object.keys(listeners).forEach(eventName => {
    socket.on(eventName, listeners[eventName])
  })

  return {
    switchProject ({ workspaceId, projectCode, memberId }) {
      socket.emit('switchProject', { workspaceId, projectCode, memberId })
    }
  }
}

export default projectSocket

