<template>
<div class="targets">
  <div class="targets__header" v-if="targetsLoaded && targets.length > 0">
    <ui-dropdown
      :options="targetStatuses"
      :value="status"
      :multiple="false"
      reference-class="targets-dropdown"
      popover-class="ui-dropdown_no-border ui-dropdown_round"
      :width-by-popover="true"
      key-field="name"
      @input="onStatusChange"
    >
      <template v-slot:default>
        <div class="targets-dropdown__selected">
          {{ status.label }}
          <chevron-down background="#2F3750" class="task-spheres__chevron-icon"/>
        </div>
      </template>

      <template v-slot:option="{ option }">
        {{ option.label }}
      </template>
    </ui-dropdown>

    <ui-button ui-class="light ui-button-new-target" @click="onCreate" :disabled="!canUpdateGoals">
      <plus background="#638FFF" class="icon-round-plus"/> {{ $t('goals.newGoalBtn') }}
    </ui-button>
  </div>

  <target-list
    :targets="targets"
    :is-tour="isTour"
    :targets-loaded="targetsLoaded"
    @edit="onUpdate"
    v-if="targetsLoaded && targets.length > 0"
  />

  <div class="target-list" v-if="targetsLoaded && targets.length === 0">
    <transition name="zoom" appear>
      <div class="targets-empty">
        <div class="targets-empty__title">
          {{ $t('goals.createTitle') }}
        </div>

        <div class="targets-empty__content">
          {{ $t('goals.noGoals.feature') }}
        </div>

        <div class="targets-empty__form">
          <target-form :focus-on-name="false"/>
        </div>
      </div>
    </transition>
  </div>

  <target-modal v-if="isModalFormOpen" :target-id="formTargetId" @close="isModalFormOpen = false"/>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import UiButton from 'scorework-ui/src/components/UiButton'

import ChevronDown from "@/components/icons/ChevronDown"
import Plus from "@/components/icons/Plus"
import TargetForm from "@/components/targets/TargetForm"
import TargetList from "@/components/targets/TargetList"
import TargetModal from "@/components/targets/TargetModal"
import UiDropdown from "@/components/tasks/UiDropdown"

export default {
  name: 'Targets',

  components: { ChevronDown, Plus, TargetForm, TargetModal, TargetList, UiButton, UiDropdown },

  props: {
    targets: {
      type: Array,
      required: true
    },

    targetsLoaded: {
      type: Boolean,
      required: true
    },

    isTour: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const targetStatuses = [
      { label: this.$t('goals.activeMany'), name: 'active' },
      { label: this.$t('goals.finishedMany'), name: 'finished' },
    ]

    return {
      isModalFormOpen: false,
      formTargetId: 0,
      status: targetStatuses[0],
      targetStatuses
    }
  },

  computed: {
    ...mapGetters(['canUpdateGoals'])
  },

  methods: {
    onStatusChange (status) {
      this.status = status
      this.$emit('status-change', status.name)
    },

    onCreate () {
      if (this.isTour) {
        return
      }
      this.formTargetId = 0
      this.isModalFormOpen = true
    },

    onUpdate ({ id }) {
      if (this.isTour) {
        return
      }
      this.formTargetId = id
      this.isModalFormOpen = true
    }
  }
}
</script>
<style lang="sass">
.targets
  box-sizing: border-box
  width: 100%
  max-width: 840px
  margin: 0 auto
  padding: 0 30px 18px

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 34px

.ui-dropdown__reference
  &.targets-dropdown
    background: var(--light-grey-color-24)
    border-radius: 8px
    padding: 12px
    min-width: 160px

    &:hover, &.ui-dropdown__reference_opened
      background: var(--grey-light-color-40)
      border-radius: 8px
      box-shadow: none
      padding: 12px

  .targets-dropdown__selected
    font-size: 14px
    color: var(--text-primary-color)
    cursor: pointer
    font-weight: 400
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
</style>
