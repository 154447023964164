import apiService from "@/services/api"
import { setupWorkHours } from "@/services/scoring"
import socketService from "@/services/socket"

export const namespaced = false

export const state = {
  isInitInProgress: false,

  workspace: {},

  features: [],

  allProjects: [],

  tags: [],

  activeTask: {},

  unseenNotifications: 0,

  countries: [],

  isTour: false,

  upgradePlanModal: {
    show: false,
    feature: '',
  }
}

export const mutations = {
  SET_INIT_IN_PROGRESS (state, value) {
    state.isInitInProgress = value
  },

  SET_WORKSPACE (state, workspace) {
    const { work_schedule: workSchedule = null } = workspace

    if (workSchedule) {
      workSchedule[0].work_hours = setupWorkHours(workSchedule[0].work_hours)
    }

    state.workspace = workspace
  },

  REMOVE_MEMBER (state, memberId) {
    state.members = state.members.filter(item => item.member_id !== memberId)
  },

  SET_FEATURES (state, data) {
    state.features = data
  },

  REFRESH_FEATURE (state, feature) {
    state.features = state.features.map((item) => item.code === feature.code ? feature : item)
  },

  SET_UNSEEN_NOTIFICATIONS (state, value) {
    state.unseenNotifications = value
  },

  SET_COUNTRIES (state, data) {
    state.countries = data
  },

  SET_IS_TOUR (state, val) {
    state.isTour = val
  },

  REFRESH_WORKSPACE (state, data) {
    state.workspace = { ...state.workspace, ...data }
  },

  SET_ALL_PROJECTS (state, projects) {
    state.allProjects = projects
  },

  SET_UPGRADE_PLAN_MODAL (state, data) {
    state.upgradePlanModal = data
  }
}

export const actions = {
  async fetchInit ({ dispatch, commit }, { project = null }) {
    commit('SET_INIT_IN_PROGRESS', true)
    try {
      const { data } = await apiService.get('workspace', { project })
      const { user, workspace, active_task = null, unseen_notifications, project: projectData } = data

      dispatch('setAuthUser', { user })

      const { features = [], ...workspaceData} = workspace

      commit('SET_FEATURES', features)

      commit('SET_WORKSPACE', workspaceData)
      commit('SET_UNSEEN_NOTIFICATIONS', unseen_notifications)

      socketService.startSocket()
      if (projectData) {
        dispatch('setProjectData', { ...projectData, isInit: true })
      }

      dispatch('setActiveTask', { task: active_task })

      return data
    } catch (e) {
      console.log(e)
      return e
    } finally {
      commit('SET_INIT_IN_PROGRESS', false)
    }
  },

  updateWorkspace ({ commit }, data) {
    return apiService.put('workspace', data)
      .then(({ data }) => {
        commit('SET_WORKSPACE', data)
      })
  },

  updateSprintSettings ({ commit }, data) {
    return apiService.put('workspace/sprint-settings', data)
      .then(({ data }) => {
        commit('SET_WORKSPACE', data)
      })
  },

  findUsers (ctx, val) {
    return apiService.get(`user/find?email=${val}`)
      .then(({ data }) => data)
  },

  fetchSpheres ({ rootGetters }) {
    const { project } = rootGetters
    console.log(project)
    return apiService.get(`projects/${project.code}/spheres`)
      .then(({ data }) => {
        return data
      })
  },

  setSpheres ({ commit }, data) {
    const spheres = data.reduce((result, item) => {
      const { children, ...sphere } = item

      result.push(sphere)
      children.forEach(sphere => result.push(sphere))

      return result
    }, [])

    commit('SET_SPHERES', spheres)
  },

  fetchWorkspace ({ commit }) {
    return apiService.get(`workspace/details`)
      .then(({ data }) => {
        commit('SET_WORKSPACE', data)
      })
  },

  async updateWorkspaceLogo ({ commit }, formData) {
    const { data } = await apiService.uploadFile('workspace/logo', formData)
    commit('SET_WORKSPACE', data)

    return data
  },

  uploadImage (ctx, { formData, progressCallback = () => '' }) {
    return apiService.uploadFile('images', formData, progressCallback())
      .then(({ data }) => {
        return data
      })
  },

  setUnseenNotifications ({ commit }, value) {
    commit('SET_UNSEEN_NOTIFICATIONS', value)
  },

  resendInvite (ctx, { memberId }) {
    return apiService.post(`members/${memberId}/resendInvite`)
  },

  fetchCountries ({ commit }) {
    return apiService.get('countries')
      .then(({ data }) => {
        commit('SET_COUNTRIES', data)
      })
  },

  setIsTour ({ commit }, val) {
    commit('SET_IS_TOUR', val)
  },

  refreshWorkspace ({ commit }, data) {
    commit('REFRESH_WORKSPACE', data)
  },

  setFeatures ({ commit }, features) {
    commit('SET_FEATURES', features)
  },

  refreshFeature ({ commit }, { feature }) {
    commit('REFRESH_FEATURE', feature)
  },

  async sendPaymentRequest () {
    const { data } = await apiService.post('workspace/payment')

    return data
  },

  setPartialMembers ({ commit }, members) {
    commit('SET_PARTIAL_MEMBERS', members)
  },

  async fetchAllProjects({ commit }) {
    const { data } = await apiService.get('workspace/projects')
    commit('SET_ALL_PROJECTS', data)
  },

  async fetchFeatures ({ commit }) {
    const { data } = await apiService.get('workspace/features')
    commit('SET_FEATURES', data)
    return data
  },

  setUpgradePlanModal({ commit }, data) {
    commit('SET_UPGRADE_PLAN_MODAL', data)
  }
}

export const getters = {
  isInitInProgress: ({ isInitInProgress }) => isInitInProgress,

  allProjects: ({ allProjects }) => allProjects,

  tags: ({ tags }) => tags,

  workspace: ({ workspace }) => workspace,

  unseenNotifications: ({ unseenNotifications }) => unseenNotifications,

  countries: ({ countries }) => countries,

  isTour: ({ isTour }) => isTour,

  features: ({ features }) => features,

  upgradePlanModal: ({ upgradePlanModal }) => upgradePlanModal,
}
