<template>
  <div class="page-view">
    <div class="page-view__content">
      <div class="page-view__title">
        <el-popover
          placement="bottom"
          popper-class="sw-popover sw-popover_no-border"
          ref="iconMenu"
        >
          <picker
            :data="emojiIndex"
            :per-line="9"
            :native="true"
            :emoji-size="25"
            title="Выберите иконку"
            color="#638FFF"
            emoji="point_up"
            @select="emj => onChangeIcon({ type: 'emoji', value: emj.native })"
          />
          <button
            class="page-view__icon"
            slot="reference"
            @click="showIconMenu = true"
          >
            {{ page.icon.value }}
          </button>
        </el-popover>

        <ui-magic-field
          :value="page.name"
          @input="name => changeData({ name }, false)"
          placeholder="Без названия"
          class="page-view__name"
          :class="{
            'sw-task-card__title_error': errors.name
          }"
        />
      </div>

      <div class="page-view-info">
        <div class="page-view-info__author">
          <div class="page-view-info__author-photo">
            <user-photo :user="author" size="xmd"/>
          </div>

          <div class="page-view-info__author-content">
            <div class="page-view-info__author-name">
              {{ author.full_name }}
            </div>

            <div class="page-view-info__author-date">
              {{ formattedCreatedAt }}
            </div>
          </div>
        </div>

        <div class="page-view-info__options">
        </div>
      </div>
    </div>

    <editor-side-menu :editor="editor" v-if="editor"/>

    <div class="page-view__content">
      <xui-editor
        :value="page.description"
        placeholder="Содержимое страницы"
        :extensions="editorExtensions"
        :with-attaches="true"
        @input="onContentChange"
        @attach-file="onAttachFile"
        @init="onEditorInit"
      />
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import UiMagicField from "@/components/ui/UiMagicField";
  import XuiEditor from "@/components/ui/XuiEditor";
  import UserPhoto from "@/components/ui/UserPhoto"
  import EditorSideMenu from '@/components/pages/EditorSideMenu'
  import withEmojis from '@/components/ui/textEditor/withEmojis'
  import debounce from '@/utils/debounce';
  import {getPastDate} from '@/services/scoring';
  import {HoverExtension} from '@/components/ui/textEditor/hoverExtension';
  import Collaboration from '@tiptap/extension-collaboration';
  import {CollaborationCursor} from '@tiptap/extension-collaboration-cursor';
  import * as Y from 'yjs';
  import {WebsocketProvider} from 'y-websocket';

  export default {
    name: 'PageView',

    components: { EditorSideMenu, UserPhoto, XuiEditor, UiMagicField },

    mixins: [withEmojis],

    props: {
      page: {
        type: Object,
        required: true,
      },

      errors: {
        type: Object,
        default: () => ({}),
      }
    },

    data () {
      const extensions = []
      let provider = null

      if (this.page.code) {
        const ydoc = new Y.Doc()
        provider = new WebsocketProvider(`${process.env.VUE_APP_WS_URL}/pages-ws`, this.page.code, ydoc)
        provider.on('synced', () => {
          if (!ydoc.getMap('config').get('initialContentLoaded') && this.editor) {
            ydoc.getMap('config').set('initialContentLoaded', true)

            this.editor.commands.setContent(this.page.description)
          }
        })

        extensions.push(Collaboration.configure({
          document: ydoc,
        }))
      }

      return {
        emojiIndex: this.newEmojiIndex(),
        title: '',
        description: '',
        editor: null,
        currentPos: null,
        showIconMenu: false,
        provider,
        editorExtensions: [
          HoverExtension.configure({}),
          ...extensions,
        ],
        debouncedChangeData: debounce(() => {
          this.changeData({
            description: this.editor.getJSON(),
            description_plain: this.editor.getHTML(),
          })
        }, 1500),
      }
    },

    computed: {
      ...mapGetters(['authUser', 'members']),

      formattedCreatedAt () {
        return getPastDate(this.page.createdAt).label
      },

      author () {
        return this.page.author_id
          ? this.members.find((member) => member.member_id === this.page.author_id)
          : this.authUser
      }
    },

    methods: {
      ...mapActions('pages', ['changePageData', 'updatePage']),

      onAttachFile () {

      },

      onEditorInit ({ editor }) {
        this.editor = editor

        if (!this.page.code) {
          this.changeData({
            description: this.editor.getJSON(),
            description_plain: this.editor.getHTML(),
          })
        }
      },

      onContentChange () {
        this.debouncedChangeData()
      },

      changeData (data, immediately = true) {
        this.updatePage({
          code: this.page.code,
          formData: data,
          immediately,
        })
      },

      onChangeIcon (icon) {
        this.changeData({ icon })
        this.$refs.iconMenu.doClose()
      }
    },

    created () {
      if (this.provider) {
        this.editorExtensions.push(CollaborationCursor.configure({
          provider: this.provider,
          user: {
            name: this.authUser.full_name,
            color: '#f783ac',
          },
        }))
      }
    },

    beforeDestroy() {
      this.provider?.destroy()
    }
  }
</script>
<style lang="sass">
  .page-view
    display: grid
    grid-template-columns: [side-start] 1fr [content-start] 680px [content-end] 1fr [side-end]

    &__side
      grid-column: side-start

    &__content
      grid-column: content

    &__title
      display: flex
      align-items: center
      margin-bottom: 16px
      column-gap: 8px

    &__icon
      font-size: 24px
      width: 30px
      height: 30px
      border-radius: 4px
      transition: all .2s ease-in
      cursor: pointer
      border: 0
      display: flex
      align-items: center
      justify-content: center
      background: transparent

      &:hover
        background: var(--grey-light-color-40)

    &__name
      flex: 1
      font-size: 24px
      font-weight: 600
      line-height: 30px

    &-info
      border-top: 1px solid var(--block-border-bg)
      border-bottom: 1px solid var(--block-border-bg)
      padding: 12px 0
      display: flex
      justify-content: space-between
      align-items: center

      &__author
        display: flex
        column-gap: 12px
        align-items: center

        &-name
          font-size: 14px
          font-weight: 500

        &-date
          margin-top: 4px
          color: var(--text-light-color)
          font-size: 12px

    &-controls
      margin: 0 auto 16px
      width: 680px
      position: sticky
      bottom: 16px
      box-shadow: var(--popover-shadow)
      padding: 8px 16px
      background: var(--block-bg-color)
      box-sizing: border-box
      border-radius: 16px

      &__wrapper
        display: flex

</style>
