<template>
  <div class="pages-menu-section" v-draggable-list="draggableList">
    <div class="pages-menu-section__header">
      {{ project ? project.name : $t('knowledge.commonPages')}}

      <pages-menu-btn ui-class="plus" @click="() => onCreatePage({ project: project ? project.code : null })">
        <plus/>
      </pages-menu-btn>
    </div>

    <page-menu-item
      v-for="item in sectionPages"
      :item="item"
      :key="item.code"
      @create="onCreatePage"
    />
    <div class="pages-menu-section__empty" v-if="sectionPages.length === 0">
      {{ $t('knowledge.noPages') }}
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
  import Plus from '@/components/icons/Plus'
  import PagesMenuBtn from '@/components/pages/PagesMenuBtn'

  const trashHolder = document.createElement('div')

  export default {
    name: 'PagesMenuSection',

    components: { PagesMenuBtn, Plus },

    props: {
      project: {
        type: Object,
      }
    },

    data () {
      const projectCode = this.project ? this.project.code : 'common'

      return {
        projectCode,
        draggableList: {
          name: `project-pages-${projectCode}`,
          listItem: {
            project: projectCode,
          },
          callbacks: {
            onDragEnd:    this.onDragEnd,
            onDragStart:  this.onDragStart,
            onAfterItem:  this.onAfterItem,
            onBeforeItem: this.onBeforeItem,
            onAboveItem:  this.onAboveItem,
            onLeaveList:  this.onLeaveList
          },
          options: {
            hideElement: false,
            canMovedInto: true,
            unsetTargetWhenLeaveList: true,
            externalDropLists: ['stage-tasks']
          }
        }
      }
    },

    computed: {
      ...mapGetters(['projects']),
      ...mapGetters('pages', ['mainPages']),

      sectionPages () {
        return this.mainPages[this.project ? this.project.code : 'common'] || []
      }
    },

    methods: {
      ...mapActions('pages', ['movePage']),

      onCreatePage (query) {
        this.$router.push({ name: 'pages.create', query })
      },

      onDragStart (listEl, el) {
        if (el) {
          //this.setupHolder(el)
          //el.after(trashHolder)
        }
      },

      onDragEnd (listEl, item, targetItem, position, listItem) {
        if (item.code === targetItem?.code) {
          return
        }

        this.movePage({
          code: item.code,
          target: targetItem ? targetItem.code : null,
          project: listItem?.project || null,
          position,
        })
      },

      onAfterItem (listEl, targetEl) {
        console.log('after', targetEl)
        if (!listEl.contains(trashHolder)) {
          //this.setupHolder(targetEl)
        } else {
          //listEl.removeChild(trashHolder)
        }
        //listEl.insertBefore(trashHolder, targetEl.nextSibling)
      },

      onAboveItem (listEl, targetEl) {
        console.log('above', targetEl)
      },

      onBeforeItem (listEl, targetEl) {
        console.log('before', targetEl)

        if (!listEl.contains(trashHolder)) {
          //this.setupHolder(targetEl)
        } else {
          //listEl.removeChild(trashHolder)
        }
        //listEl.insertBefore(trashHolder, targetEl)
      },

      // eslint-disable-next-line
      onLeaveList (listEl) {
        //this.clearDraggingChanges(listEl)
      },

      clearDraggingChanges (listEl) {
        if (listEl.contains(trashHolder)) {
          listEl.removeChild(trashHolder)
        }

        this.isActiveDropZone = false
      },

      // eslint-disable-next-line
      setupHolder (el) {
        trashHolder.className = 'page-drag-placeholder'
      },
    },

    created () {
      const externalDropLists = this.projects.filter((item) => item.code !== this.projectCode)
        .map((item) => `project-pages-${item.code}`)

      if (this.projectCode !== 'common') {
        externalDropLists.push(`project-pages-common`)
      }

      this.draggableList.options.externalDropLists = externalDropLists
    }
  }
</script>
<style lang="sass">
.pages-menu-section
  margin-bottom: 16px

  &.draggable-list_active
    background: var(--primary-light-color)

  &__header
    color: var(--grey-color-100)
    text-transform: uppercase
    font-size: 12px
    padding: 0 12px
    margin-bottom: 12px
    font-weight: 500
    display: flex
    justify-content: space-between
    box-sizing: border-box
    min-height: 22px
    align-items: center

    &:hover
      .pages-menu-btn
        display: flex

  &__empty
    color: var(--grey-color-100)
    padding: 0 24px
    font-size: 13px

.page-drag-placeholder
  background: var(--placeholder-bg)
  width: 100%
  height: 3px
</style>
