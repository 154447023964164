import { mapActions } from 'vuex'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import ContentImage from "@/components/ui/textEditor/imageExtension"

import js from 'highlight.js/lib/languages/javascript'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
import php from 'highlight.js/lib/languages/php'
import go from 'highlight.js/lib/languages/go'
import sql from 'highlight.js/lib/languages/sql'
import java from 'highlight.js/lib/languages/java'
import swift from 'highlight.js/lib/languages/swift'
import python from 'highlight.js/lib/languages/python'
import rust from 'highlight.js/lib/languages/rust'
import csharp from 'highlight.js/lib/languages/csharp'
import c from 'highlight.js/lib/languages/c'
import ruby from 'highlight.js/lib/languages/ruby'

import { lowlight } from 'lowlight/lib/core'

lowlight.registerLanguage('html', html)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)
lowlight.registerLanguage('php', php)
lowlight.registerLanguage('go', go)
lowlight.registerLanguage('sql', sql)
lowlight.registerLanguage('java', java)
lowlight.registerLanguage('swift', swift)
lowlight.registerLanguage('python', python)
lowlight.registerLanguage('rust', rust)
lowlight.registerLanguage('csharp', csharp)
lowlight.registerLanguage('c', c)
lowlight.registerLanguage('ruby', ruby)

import OverlayGallery from '@/components/ui/OverlayGallery'
import {VueNodeViewRenderer} from "@tiptap/vue-2";
import CodeBlock from '@/components/ui/textEditor/CodeBlock'
import Paragraph from "@tiptap/extension-paragraph";
import {mergeAttributes} from "@tiptap/core";
import ImageBlock from "@/components/ui/textEditor/ImageBlock";

function getCommonExtensions (uploadFunc) {
  return [
    StarterKit.configure({
      history: false,
      heading: {
        levels: [1, 2, 3],
      },
      codeBlock: false,
      paragraph: Paragraph.extend({
        renderHTML({ HTMLAttributes }) {
          return ['p', mergeAttributes({
            textAlign: 'right'
          }, HTMLAttributes), 0]
        },
      }).configure()
    }),
    Link,
    Underline,
    CodeBlockLowlight
      .extend({
        addNodeView() {
          return VueNodeViewRenderer(CodeBlock)
        },
        addKeyboardShortcuts () {
          return {
            ...this?.parent(),
            'Tab': () => this.editor.chain().focus().insertContent("  ").run(),
          }
        }
      })
      .configure({
        lowlight,
      }
    ),
    ContentImage.extend({
      addNodeView() {
        return VueNodeViewRenderer(ImageBlock)
      }
    }).configure({
      inline: false,
      uploadFunc,
    }),
    // new TodoItem(), // NO
    // new TodoList(), // NO
  ]
}

export default {
  components: { OverlayGallery },

  data () {
    return {
      gallery: {
        open: false,
        src: ''
      }
    }
  },

  methods: {
    ...mapActions(['uploadImage']),

    getCommonExtensions () {
      const progressCallback = () => {

      }

      const uploadFunc = async (image) => {
        const formData = new FormData()
        formData.append('image', image)

        const data = await this.uploadImage({ formData, progressCallback })
        const { url, thumbnails = {} } = data
        const previewUrl = thumbnails.sm || url

        return { previewUrl, url }
      }

      return getCommonExtensions(uploadFunc)
    },

    listenImageOpening ({ view }) {
      view.dom.addEventListener('click', (e) => {
        if (e.target.tagName !== 'IMG') {
          return
        }

        this.gallery = {
          open: true,
          src: e.target.dataset.src || e.target.src
        }

        e.stopPropagation()
      })
    }
  },
}
