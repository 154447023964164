const messages = {
  pageTitles: {
    login: 'Авторизация',
    join: 'Присоединиться к пространству {workspace}',
    onBoarding: 'Укажите ваши сферы деятельности',
    sprints: '{workspace} - Активные спринты',
    messenger: '{workspace} - Сообщения',
    tasks: '{workspace} - Задачи',
    workload: '{workspace} - Рабочая загрузка',
    settings: {
      workspace: '{workspace} - Настройки пространства',
      project: '{project} - Настройки проекта',
      stages: '{workspace} - Настройки стадий',
      spheres: '{workspace} - Настройки сфер',
      team: '{workspace} - Управление командой',
      profile: '{workspace} - Настройки профиля',
      notifications: '{workspace} - Настройки уведомлений',
    },
    setup: {
      general: 'Создание рабочего пространства',
      members: '{workspace} - Участники',
      spheres: '{workspace} - Сферы деятельности',
    }
  },
  tasks: {
    actions: {
      finish: 'Завершить',
      start: 'Начать выполнение',
      returnToBacklog: 'Вернуть в Backlog',
      returnToPreviousState: 'Вернуть на предыдущую стадию',
      pause: 'Поставить на паузу',
      moveForward: 'Перевести на следующую стадию',
      deleteTask: 'Удалить задачу'
    },
    deleteConfirmation: {
      title: 'Удаление задачи',
      description: 'Вы действительно хотите удалить задачу? <p>После этого ее невозможно будет восстановить.</p>',
      submit: 'Удалить задачу',
      cancel: 'Оставить, как есть',
      error: 'Невозможно удалить задачу, попробуйте позже'
    },
    widget: {
      remainTime: 'Время до окончания рабочего дня',
      status: {
        open: 'Ожидает',
        inProgress: 'В работе',
        onPause: 'На паузе'
      },
    },
    offSchedule: {
      title: 'Рабочий день завершён',
      description: 'Учёт времени остановлен'
    },
    title: 'Задачи',
    searchPlaceholder: 'Поиск по задачам',
    allTasks: 'Все задачи',
    backlog: 'Бэклог',
    createTask: 'Создать задачу',
    newTask: 'Новая задача',
    newTaskBtn: 'Задача',
    moveTaskToTheNextStage: 'Перевести задачу на следующую стадию',
    youCanSelectNextStageAndAssigneeForTask: 'Вы можете выбрать следующую стадию и исполнителя для задачи.',
    stage: 'Стадия',
    assignee: 'Исполнитель',
    comment: 'Комментарий',
    commentForNextAssignee: 'Комментарий для следующего исполнителя',
    finishTask: 'Завершить задачу',
    notSelected: 'Не выбран',
    returningTaskToAPreviousStage: 'Возврат задачи на предыдущую стадию',
    pleaseIndicateTheReasonForReturningTheTask: 'Пожалуйста, укажите причину возврата задачи, это поможет исполнителю быстрее определить и устранить проблему.',
    description: 'Описание',
    returningReasonDescription: 'Описание причины возврата',
    leaveAsIs: 'Оставить как есть',
    returnTask: 'Вернуть задачу',
    anotherTaskInWork: 'Другая задача в работе',
    youAlreadyHaveATaskInWork: 'У вас уже есть задача в работе, чтобы начать работать над новой необходимо сначала закончить предыдущую, либо поставить ее на паузу',
    currentTask: 'Текущая задача',
    inProgress: 'В работе',
    days: 'Дни',
    hours: 'Часы',
    minutes: 'Минуты',
    enterTheComment: 'Введите комментарий',
    commentWasSuccessfullyAdded: 'Комментарий успешно добавлен',
    started: 'Начата',
    resumed: 'Возобновлена',
    finished: 'Завершена',
    paused: 'Приостановлена',
    returned: 'Возвращена',
    planned: 'По плану',
    isOpen: 'Открыта',
    completed: 'Завершена',
    on: 'На ',
    slower: 'медленнее',
    faster: 'быстрее',
    isOverdue: 'Просрочена',
    overdueBy: 'Просрочена на',
    notStarted: 'Не начата',
    unknown: 'Неизвестно',
    unknownTime: 'Время неизвестно',
    from: 'из',
    returnedFrom: 'возвращена из',
    noScore: 'Без оценки',
    thereAreNoTasksAvailableAtThisStage: 'В этой стадии нет доступных задач',
    emptyBacklog: 'В бэклоге нет доступных задач',
    emptyArchive: 'Не найдено задач',
    createNewTask: 'Создание новой задачи',
    newTaskName: 'Название новой задачи',
    enterTaskDescription: 'Введите описание задачи...',
    messages: 'Сообщения',
    attachments: 'Вложения',
    timeline: 'Активность',
    processedTime: 'Время выполнения',
    attachFile: 'Прикрепить файл',
    comments: 'Комментарии',
    addAttachment: 'Добавить вложение',
    noGoal: 'Нет цели',
    score: 'Оценка',
    took: 'Было затрачено',
    noSphere: 'Нет сферы',
    search: {
      notFound: 'Ничего не найдено'
    },
    numberIsCopied: 'Номер скопирован',
    now: 'Сейчас',
    archive: 'Завершенные',
    pause: 'Поставить на паузу',
    allMembers: 'Все участники',
    allSprints: 'Все спринты',
    allGoals: 'Все цели',
    membersCount: 'Участники [{count}]',
    sprintsCount: 'Спринты [{count}]',
    goalsCount: 'Цели [{count}]',
    tasksCount: 'Задачи [{count}]',
    points: 'Сложность',
    pointsLabel: '{points} баллов | {points} балл | {points} балла | {points} баллов',
    pointsEmpty: 'Сложность',
    created: 'создана {date}',
    statuses: {
      open: 'На очереди',
      in_progress: 'В процессе',
      finished: 'Завершена',
      paused: 'На паузе',
      overdueBy: 'просрочена на {date}',
      overdueByItem: 'просрочена на {date}',
      overdue: 'В процессе',
      not_scored: 'На очереди',
      unknownFinish: 'Время завершения неизвестно'
    },
    progress: {
      startedAt: 'Дата начала',
      finishedAt: 'Дата завершения',
      beProcessedAt: 'Будет взята в работу',
      estimatedFinishAt: 'Ожидается завершение',
      estimatedFinishAtUnknown: 'Дата завершения неизвестна',
      spentTime: 'Время в работе',
      stageBreakdown: 'Ход работы по стадиям',
      noScore: 'Нет информации о сроках выполнения',
      noScoreShort: 'Нет информации',
      overdueBy: 'Сроки выполнения превышены',
      idle: 'Ожидает выполнения'
    },
    scoring: {
      hour: 'ч.',
      minutes: 'мин.',
      day: '{days} день | {days} дней'
    },

    errors: {
      notFound: {
        title: 'Упс... задача не найдена',
        text: 'Не можем найти выбранную задачу, возможно, она была удалена. Попробуйте выбрать нужную задачу на странице списка.',
      }
    },

    noAssignee: 'Без исполнителя',
  },

  comments: {
    deleted: 'Комментарий удален',
    deleteTitle: 'Удаление комментария',
    deleteDescription: 'Вы действительно хотите удалить комментарий?',
    updatedAt: 'Отредактировано ',
    error: {
      updateTitle: 'Произошла ошибка при сохранении комментария, попробуйте позже'
    }
  },

  goals: {
    title: 'Цели',
    newGoal: 'Новая цель',
    newGoalBtn: 'Цель',
    goalWasSuccessfullyClosed: 'Цель была успешно закрыта',
    goalClosed: 'Цель завершена',
    showTasks: 'Показать задачи',
    noTasks: 'Пока что не добавлено ни одной задачи',
    tasks: 'задач',
    outOf: 'из',
    finished: 'Завершен',
    progress: 'Прогресс',
    noEstimatedData: 'Нет данных',
    plannedCompletion: 'Планируемое завершение',
    assignees: 'Исполнители',
    editGoal: 'Редактирование цели',
    goalNameIsRequired: 'Название цели должно быть задано',
    goalName: 'Название цели',
    closeTheGoal: 'Завершить цель',
    stayInWork: 'Оставить в работе',
    thereAreStillUnfinishedTasksInWorkForThisGoal: 'В работе еще есть незаконченные задачи по этой цели. Вы все равно хотите закрыть цель?',
    noGoals: {
      title: 'Поставьте новую цель',
      feature: 'Заводите новые цели и объединяйте в них задачи. Это сделает рабочий процесс подконтрольным и предсказуемым, что позволит быстрее достичь результатов.',
      feature1: 'Создавайте цели и добавляйте задачи, которые нужно решить для их достижения.',
      feature2: 'Ведение целей даст вам понимание о том сколько задач осталось завершить и в какие сроки цель будет достигнута.'
    },
    createTitle: 'Создание новой цели',
    updateTitle: 'Редактирование цели',
    form: {
      name: 'Название',
      color: 'Цвет',
      descriptionPlaceholder: 'Описание цели',
      errors: {
        requiredName: 'Необходимо задать название для цели'
      },
      btnUpdate: 'Сохранить изменения',
      btnCreate: 'Создать цель',
      createdTitle: 'Цель добавлена',
      updatedTitle: 'Цель обновлена'
    },
    activeMany: 'Активные',
    finishedMany: 'Завершенные'
  },

  members: {
    schedule: 'График работы',
    workNow: 'Сейчас работает',
    you: 'вы',
    assignTask: 'Назначить задачу',
    sendMessage: 'Написать в чат',
    roles: {
      owner: 'Основатель',
      admin: 'Админ',
      member: 'Участник',
      invited: 'Новый участник'
    },
    hourlyRate: 'Стоимость часа',
    emptyHourlyRate: 'Не задана',
  },

  auth: {
    inviteToJoin: 'приглашает вас присоединиться к {workspace}',
    join: 'Присоединиться к пространству',
    singInToAccount: 'Войдите в аккаунт',
    singIn: 'Войти в пространство',
    invitation: 'Приглашение',
    enterYourEmail: 'Электронная почта',
    enterYourPassword: 'Введите пароль',
    enterYourPasswordAgain: 'Введите пароль еще раз',
    yourFirstName: 'Имя',
    yourLastName: 'Фамилия',
    emailIsRequired: 'Необходимо указать email',
    firstNameIsRequired: 'Необходимо указать имя',
    lastNameIsRequired: 'Необходимо указать фамилию',
    passwordIsRequired: 'Необходимо указать пароль',
    passwordMustBeAtLeast6Characters: 'Необходимо указать пароль',
    enterConfirmation: 'Введите подтверждение',
    confirmationDoesNotMatch: 'Подтверждение не совпадает',
    createAccount: 'Создать аккаунт',
    welcomeBack: 'С возвращением в Scorework! 👋',
    loginHint: 'Введите ваш email и пароль для входа.',
    forgetPassword: 'Забыли пароль?',
    joinSuccessTitle: 'Поздравляем, вы успешно присоединились<br/> к {workspace}! 🎉',
    declined: 'Вы отклонили приглашение присоединиться к пространству.',
    failedToUpdateInviteStatus: 'Не получилось обновить статус приглашения.',
  },

  onboarding: {
    spheres: {
      description: 'Перед началом работы укажите свои сферы деятельности, это поможет в управлении рабочим процессом.'
    }
  },

  common: {
    close: 'Закрыть',
    create: 'Создать',
    send: 'Отправить',
    proceed: 'Продолжить',
    status: 'Статус',
    cancel: 'Отменить',
    added: 'Добавлен',
    add: 'Добавить',
    save: 'Сохранить',
    saved: 'Сохранено',
    accept: 'Принять',
    delete: 'Удалить',
    decline: 'Отклонить',
    error: 'Ошибка',
    clear: 'Очистить',
    edit: 'Редактировать',
    select: 'Выбрать',
    success: 'Успешно',
    notEnoughPermissions: 'Недостаточно прав для совершения операции. Обратитесь к администратору своего воркспейса.',
    closeWindow: 'Закрыть окно',
    changesSaved: 'Изменения сохранены',
  },

  workspaces: {
    baseSettings: 'Основные настройки',
    workspaceSettingsSaved: 'Настройки рабочего пространства сохранены'
  },

  validation: {
    required: 'Поле не может быть пустым',
    integer: 'Значение должно быть целым числом',
    minScoreValue: 'Оценка должна быть не менее {minutes} минут',
    maxScoreValue: 'Оценка не должна превышать {hours} часов',
    emptyTaskTitle: 'Введите название задачи',
  },

  teams: {
    noMembers: 'Нет участников'
  },

  archive: {
    targetName: 'Название цели',
    sprintName: 'Название спринта',
    taskName: 'Название задачи',
    AssigneeName: 'Имя участника',
  },

  sprints: {
    fact: 'Фактический прогресс',
    planned: 'Планируемый прогресс',
    remainingTasks: 'Оставшиеся задачи',
    statisticsByMembers: 'Статистика по исполнителям',
    displayingMembers: 'Отображаются исполнители',
    progressOfWork: 'Ход работы',
    unaccountedTime: 'Неучтенное время',
    min: 'мин',
    h: 'ч',
    timeOfTasksInWork: 'Время задач в работе',
    efficiency: 'Эффективность',
    completedByTasks: 'Отработано по задачам',
    graph: {
      today: 'Сейчас',
      endOfSprint: 'Конец спринта'
    },
    sprintIsNotConfigured: 'Спринт не задан',
    sprintNumber: 'Спринт №{number}',
    sprintSettingsUpdate: 'Изменить настройки спринта',
    sprintSettingsSaved: 'Настройки спринтов сохранены',
    detail: {
      progressTitle: 'Ход работы'
    },
    tasksCount: '{tasks} задач | {tasks} задачи | {tasks} задач | {tasks} задач',
    noSprintsShort: 'Нет спринтов',
    noSprints: 'Нет активных спринтов',
    noSprintsText: 'Заведите первые задачи, чтобы спринт автоматически сформировался.',
    goToTasks: 'Перейти в задачи',
  },

  pages: {
    clearTasks: 'Очистить задачи',
    mySpheres: 'Мои сферы',
    setYourSpheres: 'Укажите свои сферы',
    noSpheresSelected: 'Не выбрано ни одной сферы',
    workingShift: 'Рабочий график',
    profileSaved: 'Ваш профиль успешно обновлен',
    incorrectLoginOrPassword: 'Неверный логин или пароль',
    editProfile: 'Редактирование профиля',
  },

  settings: {
    menu: {
      workspace: {
        title: 'Пространство',
        settings: 'Настройки',
        rate: 'Тариф',
        team: 'Все пользователи',
      },
      project: {
        title: 'Проект',
        spheres: 'Сферы',
        stages: 'Стадии',
        team: 'Команда',
        settings: 'Настройки',
      },
      profile: {
        title: 'Профиль',
        settings: 'Настройки',
        notifications: 'Уведомления',
      }
    },
    project: {
      title: 'Настройки проекта',
      notifications: {
        logo: 'Логотип проекта обновлен'
      }
    },
    user: {
      personal: 'Личные данные',
      projectSettings: 'Настройки в проекте',
    },
    general: {
      title: 'Настройки пространства',
      workspaceName: 'Название',
      taskPrefix: 'Префикс задач',
      workSchedule: 'Рабочий график',
      considerHolidays: 'Учитывать праздничные дни и рабочие выходные',
      country: 'Выбранная страна',
      currency: 'Валюта оплаты сотрудников команды',
      sprintDuration: 'Продолжительность спринта',
      errors: {
        name: {
          required: 'Необходимо указать название'
        },

        countryId: {
          required: 'Необходимо указать страну для учета выходных дней'
        }
      },
      notifications: {
        logo: 'Логотип пространства обновлен'
      }
    },
    rate: {
      title: 'Тарифный план',
      currentPlan: 'Ваш тариф',
      plan: 'Тариф',
      activatedAt: 'Начало действия',
      expiredAt: 'Дата окончания',
      membersCount: 'Активные пользователи',
      unlimitedRounded: '(Неограничено)',
      unlimited: 'Неограничено',
      ofCapacity: 'из {count}',
      changePlan: 'Сменить тариф',
      upgrade: {
        title: 'Время перейти на старший тариф',
        description: `<p>Вы уже достигли максимального {feature} на тарифе "{plan}".</p>
        <p>Выберите для вашей команды новый тариф и продолжайте работать вместе.</p>`,
        submit: 'Выбор тарифа',
        cancel: 'Понятно',
        features: {
          projects_count: 'числа проектов',
          members_count: 'числа пользователей',
          file_storage_size: 'доступного места для хранения файлов',
          pages_count: 'количества доступных страниц для базы знаний'
        },
      },
      ratePlans: {
        free: 'Бесплатный',
        flex: 'Гибкий',
        start: 'Старт',
        premium: 'Премиум',
        corporate: 'Корпоративный'
      },
      priceTypes: {
        per_member_month: 'пользователь/месяц',
        monthly: 'в месяц',
        annual: 'в год'
      },

      badge: {
        title: 'Тариф "{ratePlan}"',
        trialDaysRemain: 'Осталось {days} дней | Осталось {days} день | Осталось {days} дня | Осталось {days} дней',
        trialBadge: 'триал',
        trial: 'Пробный период',
        goToPay: 'Перейти к оплате',
        freeText: '<p>Расширьте свои возможности, перейдя на старший тариф!</p><p>Любой тариф имеет <b>бесплатный</b> пробный период <b>30 дней</b>.</p>',
        membersLimit: 'до {count} пользователей | до {count} пользователя | до {count} пользователей | до {count} пользователей',
        dialog: {
          title: '🎉 Заявка на оплату оставлена',
          description: '<p>Ваша заявка на оплату тарифа была успешно принята.</p> <p>В ближайшее время с вами свяжется наш менеджер и через личный кабинет на сайте вы сможете провести процесс оплаты.</p> <p>А пока все возможности текущего тарифа остаются активными для вас.</p>',
        }
      }
    },
    members: {
      addMember: 'Добавить участника',
      newUser: 'Новый пользователь',
      selected: 'Выбранные участники:',
      selectedProjects: 'Выбранные проекты:',
      projectsLabel: 'Выберите проекты',
      membersLabel: 'Выберите участников',
      projectsError: 'Выберите хотя бы один проект',
      availableNewMembersTitle: 'Можно пригласить',
      availableNewMembersCount: '{count} новых пользователей | {count} нового пользователя | {count} новых пользователей  | {count} новых пользователей',
      sendInvitations: 'Отправить приглашения',
      addedMembers: 'Добавленные участники',
      enterEmail: 'Введите email участника',
      noUsers: 'Пользователей не найдено',
      emailError: 'Введите корректный email',
      inviteMembersTitle: 'Приглашение новых участников',
      successTitle: 'Участники приглашены',
      successDescription: 'Приглашения успешно отправлены',
      tabs: {
        active: 'Активные',
        invited: 'Приглашенные',
      },
      hourlyRateLabel: 'Укажите стоимость часа работы',
      noInvitedMembers: 'Нет приглашенных пользователей',
      notifications: {
        spheres: 'Сферы участника изменены',
        role: 'Роль участника изменена',
        hourlyRate: 'Часовая ставка участника изменена',
        failed: 'Не удалось сохранить изменения',
        removedFromProject: 'Пользователь был исключен из проекта',
        removed: 'Пользователь был исключен из пространства',
        inviteFailed: 'Не удалось пригласить пользователей в проект',
      },
      projects: 'Проекты',
      removeProjectMember: 'Исключить из проекта',
      removeConfirmation: {
        title: 'Исключение пользователя',
        description: 'Вы уверены, что хотите исключить пользователя из пространства?',
        submit: 'Исключить пользователя',
        project: {
          description: 'Вы уверены, что хотите исключить пользователя из проекта?',
        }
      },
    },

    notifications: {
      title: 'Настройка уведомлений',
      description: 'Вы можете выбрать какие уведомления об изменении состоянии задач будут приходить на вашу электронную почту.',
      options: {
        task: {
          assigned: 'На вас была назначена задача',
          comment_created: 'В вашей задаче был оставлен новый комментарий',
          idle: 'Задача ожидает выполнения',
          overdue: 'Сроки задачи были превышены',
          stage_changed: 'Задача была переведена на новую стадию',
          taken: 'Задача была взята в работу',
          finished: 'Задача была завершена',
        }
      },
      saveBtn: 'Сохранить изменения',
      notify: {
        success: 'Настройки успешно изменены',
        failed: 'Не удалось сохранить настройки',
      }
    },

    memberInvite: 'Приглашение пользователя',
    memberReinviteSent: 'Почта отправлена повторно',
    memberReinviteSentFailed: 'Неудалось отправить повторное приглашение',
    team: 'Все пользователи',
    projectMembers: 'Команда проекта',
    role: 'Роль',
    actions: 'Действия',
    excluded: 'Исключен',
    removeFromTeam: 'Исключить из команды',
    resendMemberInvite: 'Отправить приглашение еще раз',
    invited: 'Приглашен',
    inviteMembers: 'Пригласить участников',
    editStage: 'Редактировать стадию',
    addChildSphere: 'Добавить группу',
    noMembersAndChildSpheres: 'Нет участников и групп',
    upload: 'Загрузить',
    nameIsRequired: 'Необходимо указать название',
    projectName: 'Название проекта',
    taskPrefix: 'Префикс задач',
    sphereName: 'Название сферы',
    profile: {
      language: 'Язык интерфейса'
    }
  },

  stages: {
    description: `Настройте жизненный цикл задач в соответствие с вашими бизнес-процессами.<p>Вы можете настроить порядок прохождения стадий для всех задач по умолчанию,</br> а также выстроить свою последовательность для задач из конкретной сферы.</p>`,
    addStages: 'Создать стадии для сферы',
    addStage: 'Добавить стадию',
    defaultSphere: 'По умолчанию',
    newStagesDescription: 'Настройте алгоритм выполнения задач для каждой сферы проекта индивидульно.',
    initialStageHint: 'Это первая стадия, куда попадает задача, когда берется в работу.</br>Она является общей для всех и ее нельзя удалить,</br> но можно переименовать.',
    finishedHint: 'Является конечной стадией для любой из задач. </br>Нельзя редактировать или удалить.',
    stageAdded: 'Стадия успешно добавлена',
    stageUpdated: 'Изменения сохранены',
    newStage: 'Новая стадия',
    editStage: 'Редактирование стадии',
    doNotNotifyAnyone: 'Не уведомлять никого',
    membersToBeNotifiedOfNewTasks: 'Участники, которых нужно уведомлять о новых задачах',
    taskScoreByDefaultInMinutes: 'Оценка задачи по умолчанию, в минутах',
    timeTrackingOfTasks: 'Учет времени выполнения задач',
    stageName: 'Название стадии',
    sphereIsRequired: 'Необходимо выбрать сферу',
    selectSphere: 'Выберите сферу',
    sphere: 'Сфера',
    stageDeleted: 'Стадия была удалена',
    completed: 'Завершенные',
    deleteAndMove: 'Удалить и перенести',
    selectStage: 'Выберите стадию',
    selectStageToMoveTasksFrom: 'Выберите стадию, куда перенести задачи из',
    deleteStage: 'Удаление стадии',
    workspaceStages: 'Стадии проекта'
  },

  setup: {
    hints: {
      tasks: {
        name: {
          title: 'Рабочий проект',
          content: 'Поток задач в проекте представляет из себя неразрывную цепочку, где задачи одного исполнителя влияют на сроки выполнения остальных звеньев.'
        },
        taskPrefix: {
          title: 'Префикс задач',
          content: 'Добавьте к номерам задач абревиатуру вашего рабочего пространства.'
        },
        schedule: {
          title: 'Рабочий график',
          content: `Указание рабочих часов дает системе возможность строить более точный прогноз в соответствие с вашим рабочим графиком.
            <p>Советуем выбрать опцию учета государственных праздников и выходных по той же причине.</p>`
        }
      },
      targets: {
        title: 'Цели',
        content: `Ставьте цели и объядиняйте в них задачи, это позволит прогнозировать время достижения результата, видеть текущий прогресс и внести своевременные изменения при отклонении от изначального плана.`
      },
      spheres: {
        allSpheres: 'Все сферы',
        tasks: {
          title: 'Распределяйте и фильтруйте задачи по сферам',
          content: `Держите под контролем каждый вид работ`
        },

        members: {
          title: 'Сферы - это зона ответственности каждого участника',
          content: `Участники также могут быть распределены по сферам, тем самым обозначив свою зону ответственности `
        }
      }
    },
    samples: {
      tasks: {
        name1: 'Сверстать макет лэндинга',
        name2: 'Доделать виджет активной задачи',
        name3: 'Внедрить форму авторизации',
        name4: 'Нарисовать макет формы оформления кредита',
        sphere1: 'Разработка',
        sphere2: 'Frontend',
        sphere3: 'Дизайн',
        sphere4: 'Backend'
      },

      targets: {
        name1: 'Запуск партнерской программы'
      }
    },
    general: {
      title: 'Создание нового проекта',
      submit: 'Создать проект'
    },
    members: {
      title: 'Пригласите остальных участников',
      cancel: 'Пригласить участников потом'
    },
    spheres: {
      title: 'Определите сферы деятельности',
      description: `Предложенная структура подходит для команд занимающихся WEB и мобильной разработкой.
                <p>Вы можете изменить сферы в соответствии с вашими процессами.</p>`,
      skipText: 'Пропустить и указать сферы позже',
      defaultList: {
        development: 'Разработка',
        backend: 'Backend',
        frontend: 'Frontend',
        design: 'Дизайн'
      }
    },
    addNestedSphere: 'Добавить вложенную сферу',
    newSphere: 'Новая сфера',
    skipAndGoToWorkspace: 'Пропустить и перейти к проекту'
  },

  spheres: {
    title: 'Сферы',
    workspaceSpheres: 'Сферы проекта',
    spheresSavedSuccessfully: 'Сферы успешно сохранены',
    addSphere: 'Добавить сферу'
  },

  notifications: {
    markAsRead: 'Прочитать все',
    assignedATaskToYou: 'назначил на вас задачу',
    takenTheTask: 'приступил к выполнению задачи',
    finishedTheTask: 'завершил задачу',
    movedTaskToStage: 'перевел задачу в стадию {stage}',
    addedCommentToTask: 'оставил новый комментарий',
    changedATaskScore: 'изменил оценку задачи',
    taskOverdue: 'Выполнение задачи превысило запланируемый срок'
  },

  messenger: {
    messages: 'Сообщения',
    chooseChatMembers: 'Выберите участников диалога',
    searchChatMember: 'Найти участника',
    goToChat: 'Перейти к диалогу',
    newChat: 'Новый диалог',
    createNewChat: 'Создать новый диалог',
    noChannels: 'У вас пока нет активных диалогов',
    startNewDialog: 'Начать новый диалог',
    indexDescription: 'Все ваши переписки с другими участниками всегда в быстром доступе и на одной площадке.',
    isTyping: 'сейчас печатает...',
    areTyping: 'сейчас печатают...',
    unreadMessages: 'Непрочитанные сообщения',
    dialogs: 'Диалоги',
    helpdesk: 'Поддержка',
    you: 'Вы',
    helpdeskTitle: 'Scorework. Поддержка',
    noLastMessage: 'Пока нет сообщений'
  },

  workload: {
    noTasks: 'Нет задач',
    createNewTask: 'Новая задача',
    tasksCount: '{tasks} задач | {tasks} задача | {tasks} задачи | {tasks} задач',
    tasksUntil: 'загруженность до',
    sortOptions: {
      tasksCount_desc: 'Сначала больше задач',
      tasksCount_asc: 'Сначала меньше задач',
      estimatedFinishAt_desc: 'Сначала большая загруженность',
      estimatedFinishAt_asc: 'Сначала меньшая загруженность',
    },
    filters: {
      allSpheres: 'Все сферы',
      spheresCount: 'выбрано {count} сфер | выбрана {count} сфера | выбрано {count} сферы | выбрано {count} сфер',
      selectedSpheres: 'Показаны сферы:',
    }
  },

  goalsProgress: {
    displayOptions: {
      showAll: 'Все задачи',
      bySpheres: 'Задачи по сферам',
    },
    eye: {
      showAll: 'Показать все сферы',
      showActive: 'Скрыть сферы без задач',
    },
    estimatedAt: 'Ожидаемое завершение',
    noTasksCreated: 'Пока не добавлено ни одной задачи к указанной цели',
    allTasksCompleted: 'Все задачи для достижения цели выполнены',
    sort: {
      tasks_desc: 'Сначала больше открытых задач',
      tasks_asc: 'Сначала близкие к завершению'
    }
  },

  taskStats: {
    suggestedScore: 'Рекомендуемая оценка',
    memberNotSelected: 'Исполнитель не выбран',
    prediction: {
      averageSpentTime: 'Среднее время выполнения',
      scoreRecommendation: 'Рекомендации по оценке',
      notEnoughData: 'Недостаточно данных, чтобы вывести статистику по завершенным задачам.',
      emptyCriteriaTitle: 'Для более точной оценки рекомендуем заполнить поля:',
      memberField: 'Исполнитель',
      sphereField: 'Сфера',
      pointsField: 'Сложность задачи',
      spentTimeByTeam: 'среди команды',
      spentTimeByMember: 'исполнителем',
      taskStatsLink: 'Статистика задач'
    }
  },

  layout: {
    inProcess: 'В работе',
    notifications: 'Уведомления',
    settings: 'Настройки',
    spheres: 'Сферы',
    stages: 'Стадии',
    members: 'Участники',
    teams: 'Команды',
    dashboard: 'Главная страница',
    tasks: 'Задачи',
    workload: 'Рабочая загрузка',
    goals: 'Обзор целей',
    pages: 'База знаний',
    sprints: 'Спринты',
    reports: 'Отчеты',
    messenger: 'Сообщения',
    profile: 'Профиль',
    logout: 'Выйти',
    complete: 'Завершить'
  },

  ui: {
    noUsersFound: 'Пользователи не найдены',
    leave: 'Выйти',
    nothingFound: 'Ничего не найдено',
    enterName: 'Введите название',
    monday: 'понедельник',
    mondayTime: 'понедельник в {time}',
    mo: 'Пн',
    tuesday: 'вторник',
    tuesdayTime: 'вторник в {time}',
    tu: 'Вт',
    wednesday: 'среда',
    wednesdayTime: 'среда в {time}',
    we: 'Ср',
    thursday: 'четверг',
    thursdayTime: 'четверг в {time}',
    th: 'Чт',
    friday: 'пятница',
    fridayTime: 'пятница в {time}',
    fr: 'Пт',
    saturday: 'суббота',
    saturdayTime: 'суббота в {time}',
    sa: 'Сб',
    sunday: 'воскресенье',
    sundayTime: 'воскресенье в {time}',
    su: 'Вс',
    today: 'сегодня',
    yesterday: 'вчера',
    gallery: {
      title: 'Просмотр изображения'
    }
  },

  user: {
    beforeStartingWorkSpecifyYourSpheres: 'Перед началом работы укажите свои сферы деятельности, это поможет в управлении рабочим процессом.',
    indicateYourSpheres: 'Укажите свои сферы',
    noSpheresSelected: 'Не выбрано ни одной сферы',
    skipAndGoToTasks: 'Пропустить и перейти к задачам',
  },

  workSchedule: {
    timeFrom: 'с',
    timeTo: 'по'
  },

  dateTime: {
    daysAgo: '{n} дней назад | {n} день назад | {n} дня назад | {n} дней назад',
    hour: 'часов | час | часа | часов',
    minute: 'минут | минута | минуты | минут',
    week: 'недель | неделя | недели | недель',
    months: {
      January: 'января',
      February: 'ферваля',
      March: 'марта',
      April: 'апреля',
      May: 'мая',
      June: 'июня',
      July: 'июля',
      August: 'августа',
      September: 'сентября',
      October: 'октября',
      November: 'ноября',
      December: 'декабря'
    },
    lessThenAnHour: 'Меньше, чем через час',
    tomorrowAt: 'Завтра',
    todayAt: 'сегодня в',
    yesterdayAt: 'вчера в',
    ago: 'назад',
    just: 'только что',
    after: 'Через',
    today: 'Сегодня',
    yesterday: 'Вчера',
    short: {
      hours: 'ч.',
      minutes: 'мин.',
      days: 'дн.',
    }
  },

  countries: {
    RU: 'Россия',
    US: 'США',
    CA: 'Канада',
    UA: 'Украина',
    KZ: 'Казахстан',
    BY: 'Беларусь',
    EE: 'Эстония',
    LV: 'Латвия',
    UK: 'Великобритания',
    AU: 'Австралия',
    notSelected: 'Не выбрана'
  },

  tour: {
    skip: 'Пропустить обучение',
    prevStep: 'Назад',
    nextStep: 'Понятно',
    complete: 'Завершить',

    fakeTasks: {
      flowName1: 'Ознакомиться с основными элементами управления Scorework',
      flowName2: 'Пригласить в пространство остальных участников команды',
      flowName3: 'Поставить новые цели и создать задачи по их достижению',
      flowName4: 'Настроить сферы и стадии',
      backlog1: 'Пройти первый спринт и отследить свою эффективность',
      backlog2: 'Перенести задачи из бэклога в поток',
    },

    fakeTargets: {
      name1: 'Настроить рабочий процесс в новом пространстве',
    },

    started: {
      title: 'Ваш первый проект готов к работе',
      description: '<p>Но сначала рекомендуем ознакомиться с основными элементами управления и возможностями системы.</p>'
      + '<p>Это займет не более 5 минут, но сэкономит гораздо больше времени в будущем.</p>',
      confirm: 'Начать знакомство',
      skip: 'Пропустить обучение'
    },

    completed: {
      title: 'Теперь вы точно готовы :)',
      description: '<p>Благодарим за знакомство с основным функционалом системы!</p>' +
        '<p>Пожалуйста, оцените насколько полезен оказался для вас ознакомительный тур?</p>',
      confirm: 'Начать работу',
      emojiError: 'Выберите эмоджи, соотвествующее вашим впечатлениям',
      feedback: {
        worst: 'Отстой',
        bad: 'Так себе',
        ok: 'Сойдет',
        good: 'Мне понравилось',
        perfect: 'Очень круто!'
      },
      feedbackPlaceholder: 'Ваш комментарий, по желанию.'
    },

    steps: {
      spheres: {
        title: 'Знакомимся со сферами',
        description: `<p>Сферы - это виды деятельности в вашем пространстве, например: разработка, дизайн, маркетинг и т.д.</p>
          <p>Для отслеживания прогресса работы по каждому направлению распределите участников команды и задачи по сферам.</p>
          <p>Фильтруйте задачи и участников по выбранной сфере.</p>`,
      },

      members: {
        title: 'Ваша команда',
        description: 'Быстрый доступ к участникам команды: <ul>' +
          '<li>фильтруйте задачи по исполнителям;</li>' +
          '<li>назначайте новые задачи;</li>' +
          '<li>пишите в чат;</li>' +
          '<li>или приглашайте новых людей.</li></ul>'
      },

      stages: {
        title: 'Стадии',
        description: `<p>Здесь отображается список стадий, в которых может находиться задача в процессе выполнения.</p> 
          <p>Задача последовательно преодолевает стадии слева-направо и, в итоге, доходит до завершения.</p>
          <p>В настройках вы можете настроить стадии, подходящие для ваших бизнес-процессов, а также создать уникальные сценарии для любой из сфер.</p>`,
      },

      tasksFlow: {
        title: 'Организованный поток задач',
        description: '<p>Все задачи идут непрерывным потоком по принципу конвейера, и выполняются последовательно сверху вниз.</p>' +
          '<p>На основе оценок и текущего прогресса система сама выстроит последовательность выполнения задач. Любые изменения в сроках автоматически влияют на прогнозы последующих задач.</p>' +
          '<p>Перетаскивая карточки в рабочей области, вы можете изменять порядок выполнения задач, а временные прогнозы будут пересчитаны автоматически.</p>'
      },

      tasksDate: {
        title: 'Состояния задач',
        inProgress: 'Задача в процессе реализации',
        overdue: 'Задача в работе и сроки были превышены',
        open: 'Задача на очереди',
        paused: 'Уже была в работе, сейчас на паузе',
      },

      tasksProgress: {
        title: 'Шкала прогресса',
        description: `<p>Шкала визуально отображает процент готовности задачи и время сколько осталось до завершения.</p>`
      },

      activeTask: {
        title: 'Виджет активной задачи',
        description: '<p>Ваша активная задача всегда под рукой, управляйте ее состоянием с помощью компактного виджета.</p><p>Виджет автоматически появится на левой панеле, как только на вас будет назначена хотя бы одна задача.</p>'
      },

      targets: {
        title: 'Создавайте цели и отслеживайте прогресс',
        description: '<p>У каждой цели отображается прогнозируемый срок ее достижения, а также число оставшихся задач.</p>'
      },

      backlog: {
        title: 'Бэклог задач',
        description: '<p>Формируйте бэклог задач перед тем, как перенести их в поток на выполнение.</p><p>В бэклоге не строятся временные прогнозы, задачи могут находиться в любом порядке.</p>'
      }
    }
  },

  currencies: {
    RUB: 'Российский рубль',
    USD: 'Доллар США',
    EUR: 'Евро',
    GBP: 'Британский фунт стерлингов',
  },

  feed: {
    noItems: 'У вас пока что нет обновлений',
    types: {
      task: {
        created: 'создал(-a) задачу',
        assigned: 'назначил(-a) задачу на',
        assignedNull: 'убрал(-а) исполнителя у задачи',
        score_changed: 'изменил(-a) оценку задачи',
        stage_changed: 'перевел(-a) задачу в стадию',
        stage_returned: 'вернул(-а) задачу на предыдущую стадию',
        file_attached: 'прикрепил(-а) файл',
        comment: {
          added: 'добавил(-а) комментарий',
          mentioned: 'Упоминание в комментарии',
        },
        progress: {
          started: 'взял(-а) задачу в работу',
          paused: 'приостановил(-а) выполнение задачи',
          overdue: 'превысил(-а) срок выполнения задачи',
          completed: 'завершил(-а) задачу',
        }
      }
    }
  },

  dashboard: {
    sprint: {
      overallProgress: 'Прогресс спринта',
      memberProgress: 'Ваш прогресс',
    },

    titles: {
      team: 'Команда',
      sprint: 'Текущий спринт',
      feed: 'Последние обновления',
      upcomingTasks: 'Следующие задачи',
      activeTask: 'Ваша текущая задача',
    },

    tasks: {
      emptyTasks: 'Сейчас на вас не назначено ни одной задачи.',
      noUpcomingTasks: 'У вас пока что нет дальнейших задач.',
      showOthers: 'Показать остальные задачи',
      hideOthers: 'Скрыть полный список',
    }
  },

  projects: {
    title: 'Проекты',
    createBtn: 'Новый проект',
    form: {
      name: 'Название проекта',
    }
  },

  workspaceMenu: {
    profile: 'Профиль',
    workspace: 'Пространство',
    memberProjects: 'Ваши проекты',
    projectMemberCount: '{count} участников | {count} участник | {count} участника | {count} участников',
  },

  report: {
    title: 'Отчеты',
    beta: 'Раздел все еще находится в разработке и активно обновляется',
    download: 'Скачать отчет',
    header: {
      member: 'Исполнитель',
      tasks: 'Задачи',
      totalTasks: 'Всего',
      completedTasks: 'Выполнено',
      expectedTasks: 'Ожидаемое',
      createdTasks: 'Создано',
      delta: 'Отклонение',
      time: 'Ход работы, ч.',
      totalScore: 'Время выполнения',
      expectedScore: 'Ожидаемое',
      points: 'Сложность задач',
      totalPoints: 'Всего',
      avgPoints: 'Средняя',
    },
    total: 'Всего',
    emptyTitle: 'Недостаточно данных для построения отчета',
    emptyText: 'Заведите первые задачи, чтобы автоматически сформировался спринт <br/>и отчет по нему.',
    selectSprint: 'Выберите спринты',
    notSelectedSprint: 'Не выбран',
  },

  editor: {
    table: {
      columns: 'Колонки',
      rows: 'Строки',
      addColumnBefore: 'Вставить колонку слева',
      addColumnAfter: 'Вставить колонку справа',
      deleteColumn: 'Удалить колонку',
      addRowBefore: 'Вставить строку сверху',
      addRowAfter: 'Вставить строку снизу',
      deleteRow: 'Удалить строку',
      deleteTable: 'Удалить таблицу',
    },

    menuItems: {
      heading1: 'Заголовок 1',
      heading2: 'Заголовок 2',
      heading3: 'Заголовок 3',
      image: 'Изображение',
      list: 'Список',
      codeBlock: 'Блок кода',
      table: 'Таблица',
    }
  },

  knowledge: {
    title: 'База знаний',
    noPages: 'Нет страниц',
    commonPages: 'Общие страницы',
    createPage: 'Создать страницу',
    publishPage: 'Опубликовать страницу',
    lastUpdates: 'Последние обновления',
    notifications: {
      published: 'Страница опубликована',
      publishedMore: 'Теперь она доступна для общего просмотра',
      publishedFailed: 'Не удалось опубликоовать страницу, попробуйте снова',
      fillPageName: 'Укажите название страницы',
    },
    emptySection: 'В этом разделе пока еще нет активных страниц.',
  }
}

export default messages
