<template>
  <div class="task-list">
    <transition-group name="zoom" tag="div" v-draggable-list="draggableList" style="position: relative" appear>
      <StageTaskItem
        v-for="(task, index) in tasks"
        :key="task.id"
        :task="task"
        :index="index"
        :allow-create="allowCreate"
        :editable="editable"
        @open="onOpenTask"
        @create="onCreateTask"
      />
      <div v-if="tasks.length === 0" class="task-list-empty" :class="emptyClasses" key="no-items">
        <transition name="zoom-in" :duration="{ enter: 30000, leave: 0 }">
        <div class="task-list-empty__content" v-if="!isActiveDropZone">
          <div class="task-list-empty__image">
            <no-tasks-icon class="task-list-empty__icon"/>
          </div>
          <div class="task-list-empty__text">
            {{ $t('tasks.thereAreNoTasksAvailableAtThisStage') }}
          </div>
        </div>
        </transition>
        <transition name="zoom-in" :duration="{ enter: 300, leave: 0 }">
          <div class="task-list-empty__trash-holder" v-if="isActiveDropZone"></div>
        </transition>
      </div>
    </transition-group>
  </div>
</template>
<script>
  import { createNamespacedHelpers } from "vuex"
  import StageTaskItem from './StageTaskItem'
  import NoTasksIcon from "@/components/icons/NoTasksIcon"
  import { sortByField } from "@/utils/arrays"

  const { mapActions: tasksActions } = createNamespacedHelpers('tasks')
  const trashHolder = document.createElement('div')

  export default {
    name: 'TaskList',

    components: { NoTasksIcon, StageTaskItem },

    props: {
      tasks: {
        type: Array,
        required: true
      },

      stage: {
        type: Object,
        required: true
      },

      allowCreate: {
        type: Boolean,
        default: true
      },

      editable: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        draggableList: {
          name: `stage-tasks`,
          callbacks: {
            onDragEnd:    this.onDragEnd,
            onDragStart:  this.onDragStart,
            onAfterItem:  this.onAfterItem,
            onBeforeItem: this.onBeforeItem,
            onLeaveList:  this.onLeaveList
          },
          options: {
            hideElement: true,
            findUnderlyingEl: this.findUnderlyingEl,
            externalDropLists: ['backlog-tasks']
          }
        },

        isActiveDropZone: false
      }
    },

    computed: {
      emptyClasses () {
        const classes = []

        if (this.isActiveDropZone) {
          classes.push(`task-list-empty_dropped`)
        }

        return classes
      }
    },

    methods: {
      ...tasksActions(['dragTask', 'endDragTask', 'sortTask', 'moveTaskToStage']),

      onDragStart (listEl, el, item) {
        if (el) {
          this.setupHolder(el)
          el.after(trashHolder)
        } else {
          this.isActiveDropZone = true
        }

        this.dragTask({ taskId: item.id, assigneeId: item.state.assignee_id })
      },

      onDragEnd (listEl, item, targetItem, position) {
        this.clearDraggingChanges(listEl)

        if (this.tasks.length === 0 && !item.stage_id) {
          this.moveTaskToStage({ taskId: item.id, assigneeId: item.state.assignee_id, stageId: this.stage.id })
        } else {
          if (targetItem && targetItem.id !== item.id) {
            this.sortTask({ taskId: item.id, assigneeId: item.state.assignee_id, targetTaskId: targetItem.id, position })
          }
        }
      },

      onLeaveList (listEl) {
        this.clearDraggingChanges(listEl)
      },

      onAfterItem (listEl, targetEl) {
        if (!listEl.contains(trashHolder)) {
          this.setupHolder(targetEl)
        }

        listEl.insertBefore(trashHolder, targetEl.nextSibling)
      },

      onBeforeItem (listEl, targetEl) {
        if (!listEl.contains(trashHolder)) {
          this.setupHolder(targetEl)
        }

        listEl.insertBefore(trashHolder, targetEl)
      },

      clearDraggingChanges (listEl) {
        if (listEl.contains(trashHolder)) {
          listEl.removeChild(trashHolder)
        }

        this.endDragTask()
        this.isActiveDropZone = false
      },

      findUnderlyingEl (underlyingItem, tasks, position) {
        if (underlyingItem.item.muted) {

          const item = underlyingItem.item

          const direction = position === 'before' ? 'desc' : 'asc'
          const sortFunc = sortByField('task_date', direction)

          const unMutedItems = tasks.filter(task => !task.item.muted)
              .sort((a, b) => sortFunc(a.item, b.item))

          //console.log(position, unMutedItems.map(t => t.item.id), unMutedItems.map(t => t.item.dragged))
          if (unMutedItems.length === 0) {
            return {
              item: underlyingItem,
              position
            }
          }

          const foundItem = unMutedItems.find(task => {
            if (position === 'before') {
              return task.item.task_date <= item.task_date
            }

            return task.item.task_date >= item.task_date
          })

          if (!foundItem) {
            return {
              item: unMutedItems.pop(),
              position: position
            }
          }

          //console.log(position, [foundItem.item.id, foundItem.item.task_date].join(', '), unMutedItems.map(t => [t.item.id, t.item.task_date].join(', ')))

          if (foundItem.item.task_date === item.task_date) {
            if (position === 'after' && item.id < foundItem.item.id) {
              position = 'before'
            } else if (position === 'before' && item.id > foundItem.item.id) {
              position = 'after'
            }
          }

          return {
            item: foundItem,
            position: position === 'before' ? 'after' : 'before'
          }
        }

        return {
          item: underlyingItem,
          position
        }
      },

      setupHolder (el) {
        trashHolder.className = 'task-item-trash-holder'
        trashHolder.style.height = el.querySelector('.task-item__block').offsetHeight + 'px'
      },

      onOpenTask (task) {
        this.$emit('open-task', task)
      },

      onCreateTask (task) {
        this.$emit('create-task', task)
      }
    }
  }
</script>
