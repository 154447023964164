<template>
<div class="task-page">
  <div class="task-page__tasks">
    <div class="task-wrapper__header">
      <div class="task-wrapper__content">
        <div class="tasks-header">
          <div class="tasks-header__left" id="tasks-spheres">
            <div class="tasks-header__title">
              {{ $t('tasks.title') }}
            </div>

            <task-spheres :spheres="spheres" :value="taskFilter.sphere_id" @input="onSphereChange" popper-class="ui-dropdown_no-border"/>
          </div>

          <div class="tasks-header__right">
            <div class="fx fx_end">
              <ui-button ui-class="light" @click="e => onCreateTask()">
                <plus class="icon-round-plus" background="#638FFF"/> {{ $t('tasks.newTaskBtn') }}
              </ui-button>
            </div>
          </div>
        </div>

        <div class="tasks-header" v-if="taskFilter.target.id">
          <div class="tasks-filter-target" :class="'tasks-filter-target_' + taskFilter.target.color">
            <target-icon class="tasks-filter-target__icon"/> {{ taskFilter.target.name }}
            <el-tooltip
              popper-class="sidebar-menu__tooltip"
              effect="dark"
              :content="$t('common.clear')"
              placement="top"
              transition="sw-slide-top"
            >
              <div class="tasks-filter-target__close" @click="onClearTarget">
                <close-icon/>
              </div>
            </el-tooltip>
          </div>
        </div>

        <div class="tasks-header">
          <div class="tasks-header__left" id="tasks-members">
            <div class="member-short-list">
              <member-popover
                v-for="member in shownMembers"
                :key="member.member_id"
                :member="member"
                @assign="onCreateTaskForMember"
              >
                <member-short-item
                  :member="member"
                  :active="taskFilter.assignee_ids.indexOf(member.member_id) !== -1"
                  @click="onMemberClick(member.member_id)"
                />
              </member-popover>
            </div>

            <el-tooltip class="item" effect="dark" :content="$t('settings.inviteMembers')" placement="top">
              <ui-button class="add-member-btn" ui-class="light" @click="showInviteModal = true">
                <plus class="add-member-btn__icon"/>
              </ui-button>
            </el-tooltip>
          </div>

          <div class="tasks-header__search">
            <task-search @open="onOpenTask"/>
          </div>
        </div>

        <ui-tabs :tabs="stages" :value="currentStage ? currentStage.id : null" @input="onChangeStage" class="task-stages" id="tasks-stages">
          <template v-slot:default="{ tab }">
            {{ tab.name }} <span>{{ stageCounts[tab.id] }}</span>
          </template>
        </ui-tabs>
      </div>
    </div>

    <div class="task-wrapper__list scrollable">
      <task-list
        :tasks="stageTasks"
        :stage="currentStage"
        v-if="!isLoading"
        @open-task="onOpenTask"
        @create-task="onCreateTask"
      />
    </div>

    <add-member-modal v-if="showInviteModal" @close="showInviteModal = false" @submit="onMembersInvited"/>
  </div>

  <div class="task-page__sidebar">
    <transition name="slide">
      <router-view :key="sidebarRouteKey"></router-view>
    </transition>

    <div class="task-page__sidebar-wrapper">
      <div class="task-page__sidebar-header">
        <ui-tabs :tabs="rightMenu" key-field="name" :value="selectedMenuItem" @input="onRightMenuChange" class="right-sidebar-menu">
          <template v-slot:default="{ tab }">
            {{ tab.label }}
          </template>
        </ui-tabs>
      </div>

      <div class="task-page__sidebar-content">
        <targets
          :targets="targets"
          :targets-loaded="targetsLoaded"
          v-if="selectedMenuItem === 'targets'"
          @status-change="onTargetStatusChange"
        />
        <backlog v-if="selectedMenuItem === 'backlog'" @open-task="onOpenTask" @create-task="onCreateTask"/>
        <archive
          v-if="selectedMenuItem === 'archive'"
          @open-task="onOpenTask"
          @create-task="onCreateTaskForMember"
        />
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {createNamespacedHelpers, mapActions, mapGetters} from "vuex"

import AddMemberModal from "@/components/settings/AddMemberModal"
import Archive from "@/components/tasks/Archive"
import Backlog from "@/components/tasks/Backlog"
import TaskList from '../components/tasks/TaskList'
import TaskSearch from "@/components/tasks/TaskSearch"
import TaskSpheres from "../components/tasks/TaskSpheres"
import UiTabs from "../components/ui/UiTabs"
import MemberShortItem from "../components/tasks/MemberShortItem"
import MemberPopover from "@/components/members/MemberPopover"
import Plus from '../components/icons/Plus'
import UiButton from 'scorework-ui/src/components/UiButton'
import CloseIcon from "@/components/icons/CloseIcon"
import TargetIcon from "@/components/icons/TargetIcon"
import Targets from "@/components/targets/Targets"
import socketService from "@/services/socket"
import tasksSocketHandlerCreator from "@/store/socket/tasks"
import pageTitleMixin from "@/views/mixins/pageTitleMixin"

const { mapActions: tasksActions, mapGetters: tasksGetters } = createNamespacedHelpers('tasks')

const SelectedMenuKey = 'tasks_menu_item'
let tasksSocketHandler = null;

export default {
  name: 'TaskListPage',

  components: {
    AddMemberModal, Archive, Backlog, CloseIcon, TaskList, UiTabs, MemberPopover, MemberShortItem, TaskSpheres,
    Plus, TargetIcon, UiButton, TaskSearch, Targets
  },

  mixins: [pageTitleMixin],

  props: {
    stageId: {
      type: Number,
      default: 0
    },

    sphereId: {
      type: Number,
      default: 0
    },

    assigneeIds: {
      type: Array,
      default: () => []
    },

    targetId: {
      type: Number,
      default: 0,
    }
  },

  data () {
    const selectedMenuItem = localStorage.getItem(SelectedMenuKey)

    return {
      rightMenu: [
        { label: this.$t('goals.title'), name: 'targets' },
        { label: this.$t('tasks.backlog'), name: 'backlog' },
        { label: this.$t('tasks.archive'), name: 'archive' }
      ],
      selectedMenuItem: selectedMenuItem || 'targets',
      isLoading: false,
      targetsLoaded: false,
      showInviteModal: false,
      targetsStatus: 'active',
      modalTargetId: 0,
    }
  },

  computed: {
    ...mapGetters([
      'activeMembers',
      'spheres',
      'stages',
      'workspace',
      'project',
      'targets',
    ]),
    ...tasksGetters([
      'stageTasks',
      'currentStage',
      'taskFilter',
      'stageCounts',
    ]),

    shownMembers() {
      if (this.taskFilter.sphere_id === null) {
        return this.activeMembers;
      }

      const sphere = this.spheres.find((item) => item.id === this.taskFilter.sphere_id)
      const sphereIds = [sphere.id]

      if (sphere.parent_id) {
        sphereIds.push(sphere.parent_id)
      } else {
        this.spheres
          .filter((item) => item.parent_id === sphere.id)
          .forEach((item) => sphereIds.push(item.id))
      }

      return this.activeMembers.filter((member) => member.project.spheres.find((sphere) => sphereIds.indexOf(sphere.id) !== -1))
    },

    pageTitle () {
      return {
        message: 'pageTitles.tasks', params: { workspace: this.workspace.name }
      }
    },

    sidebarRouteKey () {
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'taskId')) {
        return `tasks:${this.$route.params.taskId}`
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'targetId')) {
        return `targets:${this.$route.params.targetId}`
      }

      return ''
    },

    projectCode () {
      return this.$route.params.project
    }
  },

  watch: {
    projectCode () {
      this.loadData()
    },
  },

  methods: {
    ...mapActions(['fetchTargets']),
    ...tasksActions([
      'fetchTasks',
      'changeTaskFilter',
      'cleanTasks',
    ]),

    onCreateTask (sourceTask = null) {
      const query = {}

      if (sourceTask) {
        query.after_id  = sourceTask.id

        if (!sourceTask.stage_id) {
          query.backlog = 1
        }
      }

      this.$router.push({ name: 'project.tasks.create', query, params: { project: this.project.code, stageId: this.currentStage.id } })
    },

    onCreateTaskForMember ({ memberId }) {
      this.$router.push({ name: 'project.tasks.create', query: { member_id: memberId }, params: { project: this.project.code, stageId: this.currentStage.id } })
    },

    loadTasks () {
      return this.fetchTasks()
    },

    onOpenTask (task) {
      const { query } = this.$route
      this.$router.push({ name: 'project.tasks.detail', params: { project: this.project.code, taskId: task.id, stageId: this.currentStage.id }, query })
    },

    onChangeStage (stageId) {
      this.changeFilter({ stage_id: stageId })
    },

    onTargetStatusChange (status) {
      this.targetsStatus = status
      this.loadTargets()
    },

    onClean () {
      this.cleanTasks()
    },

    onSphereChange (sphere) {
      const members = this.activeMembers.filter((member) => member.project.spheres.find((sphere) => sphere.id === sphere.id))
      const filteredMembers = [];

      for (let i = 0; i < this.taskFilter.assignee_ids.length; i++) {
        const res = members.find((member) => member.member_id === this.taskFilter.assignee_ids[i])
        if (res) {
          filteredMembers.push(res.member_id)
        }
      }

      this.changeTaskFilter({ filter: { assignee_ids: filteredMembers }, updateTasks: false })
      this.changeFilter({ sphere_id: sphere.id })
    },

    changeFilter (filter) {

      this.changeTaskFilter({ filter, updateTasks: false })

      const query = Object.keys(this.taskFilter).reduce((memo, filterKey) => {
        if (filterKey !== 'stage_id') {

          if (filterKey === 'target') {
            memo.target_id = this.taskFilter.target.id
          } else if (filterKey === 'assignee_ids') {
            if (this.taskFilter.assignee_ids.length > 0) {
              memo.assignee_ids = this.taskFilter.assignee_ids.join(',')
            }
          } else if(this.taskFilter[filterKey]) {
            memo[filterKey] = this.taskFilter[filterKey]
          }
        }

        return memo
      }, {})

      this.$router.push({ query })

      return this.loadTasks()
    },

    onMemberClick (memberId) {
      this.changeFilter({ assigneeId: memberId })
    },

    onRightMenuChange (name) {
      this.selectedMenuItem = name
      localStorage.setItem(SelectedMenuKey, name)
    },

    onClearTarget () {
      this.changeFilter({ target: {}, target_id: null })
    },

    onMembersInvited () {
      this.showInviteModal = false
    },

    loadTargets () {
      this.targetsLoaded = false
      return this.fetchTargets({ status: this.targetsStatus }).finally(() => {
        this.targetsLoaded = true

        if (this.targetId) {
          this.changeTaskFilter({
            filter: {
              target: this.targets.find((item) => item.id === this.targetId),
            },
            updateTasks: false,
          })
        }
      })
    },

    async loadData () {
      const filter = {
        stage_id: this.stages[0].id,
        target_id: this.targetId || null,
      }

      if (this.stageId) {
        filter.stage_id = this.stageId
      }

      if (this.sphereId) {
        filter.sphere_id = this.sphereId
      }

      if (this.assigneeIds.length > 0) {
        filter.assignee_ids = this.assigneeIds
      }

      this.changeTaskFilter({ filter, updateTasks: false })

      this.isLoading = true
      try {
        await Promise.all([
          this.loadTasks(),
          this.loadTargets(),
        ])
      } finally {
        this.isLoading = false
      }
    }
  },

  async created () {
    if (!this.$route.params.project) {
      this.$router.push({ name: 'project.tasks', params: { project: this.project.code } })
      return
    }

    await this.loadData()
    tasksSocketHandler = tasksSocketHandlerCreator(this.$store, this.$router, () => this.fetchTasks())
    tasksSocketHandler.subscribe(socketService.socket)
  },

  beforeDestroy () {
    tasksSocketHandler.unsubscribe(socketService.socket)
  }
}
</script>
<style lang="sass">
.ui-button.add-member-btn
  width: 32px
  height: 32px
  padding: 0
  display: flex
  align-items: center
  justify-content: center

.add-member-btn__icon
  .path-fill
    fill: var(--primary-color)

.ui-dropdown__reference.targets-dropdown
  background: var(--light-grey-color-24)
  border-radius: 8px
  padding: 12px
  min-width: 160px

.targets__selected
  font-size: 14px
  color: var(--text-primary-color)
  cursor: pointer
  font-weight: 400
  justify-content: space-between
  display: flex
  align-items: center
  width: 100%

.task-page__targets-header
  padding: 2px 30px 46px
  display: flex
  justify-content: space-between
  margin: 0 auto
  width: 100%
  max-width: 823px
  -webkit-box-sizing: border-box
  box-sizing: border-box
  display: -webkit-box
  display: -ms-flexbox
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: justify
  -ms-flex-pack: justify

.ui-button-new-target
  margin-left: 16px

.target-filter-opened
  border-radius: 8px !important
  box-shadow: none !important
  &:hover
    border-radius: 8px !important
    box-shadow: none !important

.target-filter-option
  padding-top: 14px !important
  padding-bottom: 14px !important

.target-filter-active-option
  background: var(--light-grey-color-24) !important
  border-radius: 8px

.dropdown-sphere-item__targets
  min-width: 132px

.ui-dropdown_popover_targets
  padding: 0 !important
  margin-top: 10px !important
  border-radius: 8px !important
</style>
