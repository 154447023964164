import apiService from '@/services/api'
import {transformPage, transformPageCard} from '@/store/transformers/pages'
import {DateTime} from 'luxon'
import debounce from '@/utils/debounce';

export const namespaced = true

export const state = {
  mainPages: {},
  subPages: {},
  page: null,
  pagePreData: {},
  recentlyUpdated: {},
  parentSelectorPages: {}
}

export const mutations = {
  SET_MAIN_PAGES (state, data) {
    state.mainPages = data.reduce((pagesByProject, item) => {
      const projectId = item.project || 'common'
      if (!pagesByProject[projectId]) {
        pagesByProject[projectId] = []
      }

      pagesByProject[projectId].push(item)

      return pagesByProject
    }, {})
  },

  SET_MAIN_SECTION (state, { project, pages }) {
    state.mainPages = {
      ...state.mainPages,
      [project || 'common']: pages,
    }
  },

  SET_SUB_PAGES (state, { data, parent }) {
    state.subPages = {
      ...state.subPages,
      [parent]: data,
    }
  },

  SET_RECENTLY_UPDATED (state, { project, root, data }) {
    const projectCode = root ? 'root' : project

    state.recentlyUpdated = {
      ...state.recentlyUpdated,
      [projectCode]: data,
    }
  },

  SET_PAGE (state, data) {
    state.page = transformPage(data)
  },

  SET_PAGE_PARENTS (state, data) {
    state.page = {
      ...state.page,
      ...data
    }
  },

  SET_PAGE_PRE_DATA (state, data) {
    state.pagePreData = data
  },

  REFRESH_PAGE (state, page) {
    if (!page.parent) {
      const projectCode = page.project || 'common'
      const projectPages = state.mainPages[projectCode] || []

      state.mainPages = {
        ...state.mainPages,
        [projectCode]: projectPages.map((item) => {
          if (item.code !== page.code) {
            return item
          }

          return page
        })
      }
    } else {
      const parentPages = state.subPages[page.parent] || []

      state.subPages = {
        ...state.subPages,
        [page.parent]: parentPages.map((item) => {
          if (item.code !== page.code) {
            return item
          }

          return page
        })
      }
    }

    if (state.page && state.page.code === page.code) {
      state.page = {
        ...state.page,
        icon: page.icon,
        name: page.name,
      }
    }
  },

  SET_PARENT_SELECTOR_PAGES (state, { data, parent }) {
    state.parentSelectorPages = {
      ...state.parentSelectorPages,
      [parent]: data,
    }
  },

  UPDATE_PAGE (state, data) {
    state.page = {
      ...state.page,
      ...data,
    }
  }
}

export const actions = {
  async fetchMainPages ({ commit }) {
    const { data } = await apiService.get(`pages`)

    commit('SET_MAIN_PAGES', data)
  },

  async fetchSubPages ({ commit }, { parent }) {
    const { data } = await apiService.get(`pages`, { parent })

    commit('SET_SUB_PAGES', { data, parent })
    return data
  },

  async fetchRecentlyUpdated ({ commit }, { project, root = false }) {
    const { data } = await apiService.get('pages/recently-updated', { project, root: root ? 1 : 0 })

    commit('SET_RECENTLY_UPDATED', { project, root, data: data.map((item) => transformPageCard(item)) })
  },

  async fetchPage ({ commit, dispatch, rootGetters }, { code, defaultValues }) {
    if (!code) {
      const defaultData = {
        author_id: rootGetters.authUser.member_id,
        ...defaultValues
      }

      const page = newPageInstance(defaultData)
      commit('SET_PAGE', page)

      if (page.parent) {
        dispatch('fetchPageParents', { code: page.parent })
      }

      return Promise.resolve(page)
    }

    const { data } = await apiService.get(`pages/${code}`)

    commit('SET_PAGE', data)

    return data
  },

  async fetchPageParents ({ commit }, { code }) {
    const { data } = await apiService.get(`pages/${code}/parents`)
    commit('SET_PAGE_PARENTS', data)

    return data
  },

  setPagePreData ({ commit }, data) {
    commit('SET_PAGE_PRE_DATA', data)
  },

  changePageData ({ commit }, page) {
    commit('SET_PAGE', page)
  },

  async createPage (ctx, formData) {
    const { data } = await apiService.post(`pages`, formData)
    return data
  },

  async doUpdate({ commit }, { code, formData }) {
    const { data } = await apiService.patch(`pages/${code}`, formData)
    commit('SET_PAGE', data)

    return data
  },

  async updatePage ({ commit, dispatch }, { code, formData, immediately = true }) {
    commit('UPDATE_PAGE', formData)
    if (!code) {
      return
    }

    if (immediately) {
      return await dispatch('doUpdate', { code, formData })
    }

    debounceUpdatePage(dispatch, { code, formData })
  },

  async movePage (ctx, { code, target, project, position }) {
    const { data } = await apiService.post(`pages/${code}/move`, { target, project, position })

    return data
  },

  refreshSection ({ commit }, { project, parent, pages }) {
    if (!parent) {
      commit('SET_MAIN_SECTION', { project, pages })
    } else {
      commit('SET_SUB_PAGES', { parent, data: pages })
    }
  },

  refreshPage ({ commit }, { page }) {
    commit('REFRESH_PAGE', page)
  },

  async fetchParentSelectorChild ({ commit }, { project = null, parent = null}) {
    const { data } = await apiService.get(`pages/section`, { project, parent })
    commit('SET_PARENT_SELECTOR_PAGES', { data, parent: parent || project || 'common' })
  },

  changePageParents ({ commit }, data) {
    commit('UPDATE_PAGE', data)
  }
}

export const getters = {
  mainPages: ({ mainPages }) => mainPages,
  mainPagesByProject: ({ mainPages }) => (projectCode) => mainPages[projectCode] || [],
  subPages: ({ subPages }) => (code) => subPages[code] || [],
  page: ({ page }) => page,
  pagePreData: ({ pagePreData }) => pagePreData,
  recentlyUpdated: ({ recentlyUpdated }) => recentlyUpdated,
  parentSelectorPages: ({ parentSelectorPages }) => parentSelectorPages,
  parentSelectorPagesByParent: ({ parentSelectorPages }) => (parent) => parentSelectorPages[parent] || [],
}

const newPageInstance = (defaultData) => ({
  code: null,
  project: null,
  parent: null,
  parents: [],
  name: '',
  icon: {
    type: 'emoji',
    value: '📖',
  },
  description: null,
  description_plain: '',
  created_at: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
  ...defaultData
})

const debounceUpdatePage = () => {
  let updatedData = {}

  const debouncedUpdate = debounce(async (doUpdate) => {
    await doUpdate()
    updatedData = {}
  }, 1000)

  return (dispatch, { code, formData }) => {
    updatedData = {
      ...updatedData,
      ...formData
    }

    debouncedUpdate(() => dispatch('doUpdate', { code, formData: updatedData }))
  }
}

