import { mapGetters } from 'vuex'
import {isAdmin, isOwner, RoleAdmin, RoleOwner} from '@/helpers/userRoles'

export default {
    computed: {
        ...mapGetters(['authUser']),

        roleOwner () {
            return RoleOwner
        },

        roleAdmin () {
            return RoleAdmin
        },

        isAdmin () {
            return isAdmin(this.authUser)
        },

        isOwner () {
            return isOwner(this.authUser)
        }
    }
}