<template>
  <div class="task-item" :class="taskItemClasses" @dblclick="onClick">

    <div class="task-item__target-label" :class="{['target-label_' + task.target.color]: true}" v-if="task.target"></div>

    <div class="task-item__block">
      <div class="task-item__head">
        <div class="task-item__head-item task-item__head-item_first">
          <div class="task-item__target" v-if="false && task.target">
            <target-icon/>
          </div>
          <div class="task-item__prop">
            <el-tooltip
              effect="dark"
              :content="$t('tasks.numberIsCopied')"
              placement="top"
              transition="sw-slide-top"
              :manual="true"
              :value="showNumberTooltip"
            >
              <div class="task-item__prop-item task-item__number" @click="onCopyNumber">
                {{ taskNumber }}
              </div>
            </el-tooltip>
          </div>

          <a href="#" class="task-item__title-link" @click.prevent="onOpenTask">{{ task.name }}</a>
        </div>

        <div class="task-item__head-item">
          <template v-if="displayStage">
            <div class="task-item__sphere" v-if="taskState.stage">
              {{ taskState.stage.name }}
            </div>

            <div class="task-item__sphere" v-else>
              {{ task.status === 'backlog' ? 'Backlog' : $t('tasks.finished') }}
            </div>
          </template>

          <sphere-badge class="task-item__sphere-badge" :sphere="task.sphere" v-if="task.sphere"/>

          <div class="task-item__assignee" @click.stop="e => {}">
            <user-photo :user="taskState.assignee" :show-online="false" v-if="!editable"/>
            <el-popover
                v-else
                placement="bottom"
                ref="assigneePopper"
                trigger="click"
                popper-class="sw-popover task-item-popover"
                :visible-arrow="true"
            >
              <div class="ui-dropdown__option-list" ref="list">
                <div
                    v-for="member in assigneesList"
                    :key="member.member_id"
                    class="ui-dropdown__option"
                    :class="{ 'ui-dropdown__option_active': member.member_id === taskState.assignee.member_id}"
                    @click="onAssigneeChange(member)"
                >
                  <task-assignee :user="member"/>
                </div>
              </div>

              <user-photo class="task-item__assignee-ref" :user="taskState.assignee" :show-online="false" slot="reference"/>
            </el-popover>
          </div>
        </div>
      </div>

      <div class="task-item__info">
        <div class="task-item__prop">
          <el-tooltip
            effect="dark"
            :content="$t('tasks.statuses.' + taskStatus)"
            placement="top"
            transition="sw-slide-top"
          >
          <div class="task-item__status" :class="statusClasses">
            <component :is="statusIcon"></component>
          </div>
          </el-tooltip>

          <div class="task-item__status-details">
            <el-tooltip
                effect="dark"
                :content="$t(estimatedLabelTooltip)"
                placement="top"
                transition="sw-slide-top"
            >
              <div class="task-item__prop-item task-item__estimated-date" :class="statusLabelClasses">
                {{ getEstimatedTimeLabel }}
              </div>
            </el-tooltip>

            <div class="task-item__progress-bar" :class="progressBarClasses" v-if="task.status === 'in_progress' || task.actual_progress || task.finished_at">
              <div class="task-item__progress-bar-percent" :style="'width: ' + (progressPercent) + '%'"></div>
            </div>
          </div>
        </div>

        <div class="task-item__right-bar">
          <div class="task-item__prop-item task-item__comments" v-if="task.comments_count">
            <comments-icon class="task-item__comments-icon"/> {{ task.comments_count }}
          </div>

          <div class="task-item__prop-item task-item__comments" v-if="task.points">
            <points-icon class="task-item__comments-icon"/> {{ task.points }}
          </div>

          <div class="task-item__prop-item task-item__score" :class="scoreClasses" v-if="!editable">
            <clock-icon class="task-item__score-icon"/>
            {{ formattedScore }}
          </div>

          <div class="task-item__prop-item" v-else>
            <el-popover
                placement="bottom"
                ref="scorePopper"
                trigger="click"
                popper-class="sw-popover task-item-popover"
                @show="onOpenScorePopper"
                @hide="onCloseScorePopper"
                :visible-arrow="true"
            >
              <score-selector
                  :value="task.score"
                  @input="onScoreChange"
                  v-if="isScoreSelectorOpen"
              />
              <div class="task-item__prop-item task-item__score" :class="scoreClasses" slot="reference">
                <clock-icon class="task-item__score-icon"/>
                {{ formattedScore }}
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>

    <div class="task-list-span" v-if="withAddButton" @mouseover="showAddButton = true" @mouseleave="showAddButton = false">
      <a href="#" @click.prevent="onAddTask">
        <round-plus class="task-list-span__btn" v-if="showAddButton"/>
      </a>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import ClockIcon from "../icons/ClockIcon"
import CommentsIcon from "@/components/icons/CommentsIcon"
import UserPhoto from '../ui/UserPhoto'
import RoundPlus from "../icons/RoundPlus"
import ScoreSelector from "@/components/tasks/components/ScoreSelector"
import SphereBadge from '@/components/ui/SphereBadge'
import TargetIcon from '@/components/icons/TargetIcon'
import TaskAssignee from "@/components/tasks/components/TaskAssignee"
import TaskStatus from "@/components/tasks/TaskStatus"
import UiDropdown from "@/components/tasks/UiDropdown"

import progressMixin from "@/components/tasks/mixins/progressMixin"
import taskItemMixin from "@/components/tasks/mixins/taskItemMixin"
import PointsIcon from "@/components/icons/PointsIcon";

const { mapActions: taskActions } = createNamespacedHelpers('task')

export default {
  name: 'TaskItem',

  components: {
    PointsIcon,
    ClockIcon, CommentsIcon, UserPhoto, RoundPlus, SphereBadge, ScoreSelector, TargetIcon,
    TaskAssignee, TaskStatus, UiDropdown
  },

  mixins: [ progressMixin, taskItemMixin ],

  props: {
    index: {
      type: Number,
      required: true
    },

    openByClick: {
      type: Boolean,
      default: false
    },

    editable: {
      type: Boolean,
      default: false
    },

    displayStage: {
      type: Boolean,
      default: true
    },

    withAddButton: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showNumberTooltip: false,
      showAddButton: false,
      isScoreSelectorOpen: false,
    }
  },

  computed: {
    ...mapGetters(['activeMembers']),

    assigneesList () {
      const memberList = [...this.activeMembers]
      const result = []

      const assigneeId = this.taskState.assignee ? this.taskState.assignee.member_id : null
      if (assigneeId) {
        const memberIndex = memberList.findIndex(member => member.member_id === assigneeId)

        if (memberIndex !== false) {
          memberList.splice(memberIndex, 1)
          result.push(this.taskState.assignee)
        }
      }

      result.push({
        member_id: null,
        first_name: this.$t('tasks.notSelected')
      }, ...memberList)

      return result
    },

    scoreClasses () {
      const classes = []

      if (!this.task.score) {
        classes.push('task-item__score_no-score')
      }

      return classes
    },

    taskItemClasses () {
      const classes = []

      if (this.openByClick) {
        classes.push('task-item_clickable')
      }

      return classes
    }
  },

  methods: {
    ...taskActions(['updateTaskState', 'updateTaskField']),

    onClick () {
      if (!this.openByClick) {
        return
      }

      this.onOpenTask()
    },

    onOpenScorePopper () {
      this.isScoreSelectorOpen = true
    },

    onCloseScorePopper () {
      this.isScoreSelectorOpen = false
    },

    onAssigneeChange (assignee) {
      this.doTaskStateUpdate({ assignee_id: assignee.member_id })
        .then(() => {
          this.$refs.assigneePopper.doClose()
        })
    },

    onScoreChange (score) {
      this.doTaskFieldUpdate('score', score)
        .then(() => {
          this.$refs.scorePopper.doClose()
        })
    },

    doTaskStateUpdate (state) {
      return this.updateTaskState({
        id: this.task.id,
        state
      })
    },

    doTaskFieldUpdate (field, value) {
      return this.updateTaskField({
        id: this.task.id,
        field, value
      })
    },

    onCopyNumber () {
      const fakeEl = document.createElement('textarea')
      fakeEl.value = this.taskNumber
      fakeEl.setAttribute('readonly', '')
      fakeEl.style.position = 'absolute'
      fakeEl.style.left = '-9999px'
      document.body.appendChild(fakeEl)
      fakeEl.select()

      document.execCommand('copy')
      document.body.removeChild(fakeEl)

      this.showNumberTooltip = true

      setTimeout(() => {
        this.showNumberTooltip = false
      }, 1500)
    }
  }
}
</script>
