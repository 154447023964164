import apiService from "../../services/api"
import authService from "../../services/auth"
import socketService from "@/services/socket"
import {setupWorkHours, workHoursToUtc, buildTimezoneOffsetString } from "@/services/scoring";
import { TARGET_CAN_UPDATE } from "@/helpers/permissions"
import {RoleOwner} from "@/helpers/userRoles"

export const state = {
  isAuth: authService.isAuth(),
  authUser: {},
  isUserInitialized: false,
  invitation: {},
  projects: []
}

export const mutations = {
  SET_AUTH_USER (state, user) {
    const { work_schedule: workSchedule } = user

    if (workSchedule) {
      workSchedule[0].work_hours = setupWorkHours(workSchedule[0].work_hours)
    }

    state.authUser = user
  },

  SET_USER_INITIALIZED (state, value) {
    state.isUserInitialized = value
  },

  SET_IS_AUTH (state, val) {
    state.isAuth = val
  },

  SET_INVITATION (state, data) {
    state.invitation = data
  },

  REFRESH_USER (state, data) {
    state.authUser = {
      ...state.authUser,
      ...data
    }
  },

  SET_PROJECTS (state, projects) {
    state.projects = projects
  },

  REFRESH_PROJECT (state, project) {
    state.projects = state.projects.map((item) => item.code === project.code ? project : item)
  },
}

export const actions = {
  register ({ dispatch }, data) {
    return apiService.post('auth/register', { ...data, timezone: buildTimezoneOffsetString() })
      .then(({ data }) => {
        return dispatch('makeAuth', data)
      })
  },

  fetchUserMe ({ commit }) {
    return apiService.get('auth/me')
      .then(({ data }) => {
        commit('SET_USER_INITIALIZED', true)

        const { projects, ...userData } = data
        commit('SET_AUTH_USER', userData)
        commit('SET_PROJECTS', projects)
      })
  },

  setAuthUser ({ commit, dispatch }, { user }) {
    commit('SET_USER_INITIALIZED', true)
    const { projects = null, ...userData } = user
    commit('SET_AUTH_USER', userData)

    if (projects) {
      commit('SET_PROJECTS', projects)
    }

    dispatch('refreshUserTimezone', { timezone: user.timezone })
  },

  login ({ dispatch }, data) {
    const { email, password } = data

    return apiService.post('auth/login', { email, password })
      .then(({ data }) => {
        return dispatch('makeAuth', data)
      })
  },

  makeAuth ({ commit }, token) {
    const { access_token } = token

    authService.setToken(access_token)
    apiService.newClient()

    commit('SET_IS_AUTH', true)
  },

  logout ({ commit }) {
    apiService.removeToken()
    commit('SET_AUTH_USER', {})
    commit('SET_IS_AUTH', false)

    socketService.disconnect()
  },

  updateProfile ({ commit }, data) {
    return apiService.patch('user', data)
      .then(({ data  }) => {
        commit('SET_AUTH_USER', data)

        return data
      })
  },

  updateMemberProfile ({ commit }, data) {
    const { work_schedule: workSchedule, ...memberData } = data

    if (workSchedule) {
      memberData.work_schedule = [
        {
          days: [...workSchedule[0].days],
          work_hours: workHoursToUtc(workSchedule[0].work_hours)
        }
      ]
    }

    return apiService.patch(`members`, memberData)
      .then(({ data  }) => {
        commit('SET_AUTH_USER', data)

        return data
      })
  },

  updateProjectMemberProfile ({ dispatch, rootGetters }, formData) {
    const { project } = rootGetters
    return apiService.patch(`projects/${project.code}/members`, formData)
      .then(({ data  }) => {
        dispatch('setProjectMember', data)

        return data
      })
  },

  updateProfilePhoto ({ commit }, formData) {
    return apiService.uploadFile('user/photo', formData)
      .then(({ data  }) => {
        commit('SET_AUTH_USER', data)
      })
  },

  fetchInvitation ({ commit, state }, { code }) {
    const url = state.isAuth
      ? `invitation/${code}`
      : `auth/invitation/${code}`

    return apiService.get(url)
      .then(({ data }) => {
        commit('SET_INVITATION', data)

        return data
      })
  },

  updateInvitation ({ state }, { code, action }) {
    const url = state.isAuth
    ? `invitation/${code}`
    : `auth/invitation/${code}`

    return apiService.post(url, {
      action
    })
  },

  refreshUserTimezone ({ dispatch }, { timezone }) {
    const currentTz = buildTimezoneOffsetString()

    if (currentTz !== timezone) {
      return dispatch('updateMemberProfile', { timezone: currentTz })
    }

    return Promise.resolve()
  },

  refreshUser ({ commit }, data) {
    commit('REFRESH_USER', data)
  },

  refreshUserProject ({ commit }, { project }) {
    commit('REFRESH_PROJECT', project)
  },

  setProjects ({ commit }, projects) {
    commit('SET_PROJECTS', projects)
  }
}

export const getters = {
  isAuth: ({ isAuth }) => isAuth,

  authUser: ({ authUser }) => authUser,

  isUserInitialized: ({ isUserInitialized }) => isUserInitialized,

  invitation: ({ invitation }) => invitation,

  canUpdateGoals: ({ authUser }) => authUser.role === RoleOwner || authUser.permissions & TARGET_CAN_UPDATE,

  projects: ({ projects }) => projects,
}
