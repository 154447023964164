<template>
  <el-popover
    placement="bottom"
    trigger="manual"
    ref="popper"
    popper-class="ui-dropdown-ex ui-dropdown-ex_no-padding"
    v-model="isOpen"
    :visible-arrow="false"
    width="340"
  >
    <div class="task-search" :class="{'task-search_active': isActive}" slot="reference">
      <input
        type="text"
        v-model="searchTerm"
        class="task-search__input"
        ref="input"
        :placeholder="$t('tasks.searchPlaceholder')"
        @input="onTermChange"
      />
      <button class="task-search__btn" @click="onIconClick">
        <search/>
      </button>
    </div>

    <div class="task-search-results">
      <div
        v-for="item in searchResults"
        :key="item.id"
        class="task-search-item"
        @click="onClickTask(item)"
      >
        <div class="task-search-item__content">
          <div class="task-search-item__title">{{ item.name }}</div>
          <div class="task-search-item__number">{{ taskNumber(item) }}</div>
        </div>

        <div class="task-search-item__assignee">
          <user-photo :user="getAssignee(item.state.assignee_id)" size="md"/>
        </div>
      </div>

      <div v-if="searchResults.length === 0" class="task-search-results__empty">
        {{ $t('tasks.search.notFound') }}
      </div>
    </div>
  </el-popover>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex"

import debounce from "@/utils/debounce"
import Search from "@/components/icons/Search"
import UserPhoto from "@/components/ui/UserPhoto"

const { mapActions: tasksActions, mapGetters: tasksGetters } = createNamespacedHelpers('tasks')

export default {
  name: 'TaskSearch',

  components: { Search, UserPhoto },

  data () {
    return {
      debouncedSearch: debounce((term) => {
        this.searchTask({ term })
          .then(() => {
            this.isOpen = true
          })
      }, 1000),
      searchTerm: '',
      isOpen: false,
      isActive: false
    }
  },

  computed: {
    ...mapGetters(['workspace', 'members']),
    ...tasksGetters(['searchResults'])
  },

  methods: {
    ...tasksActions(['searchTask']),

    onIconClick () {
      this.isActive = true
      this.$refs.input.focus()

      document.addEventListener('click', this.onDocumentClickHandler)
    },

    onDocumentClickHandler (e) {
      if (!e.target.closest('.task-search')) {
        this.isActive = false
        this.isOpen = false
        document.removeEventListener('click', this.onDocumentClickHandler)
      }
    },

    onTermChange () {
      this.debouncedSearch(this.searchTerm)
    },

    taskNumber (task) {
      return this.workspace.task_prefix
          ? `#${this.workspace.task_prefix}-${task.number}`
          : `#${task.number}`
    },

    getAssignee (assigneeId) {
      return this.members.find(item => item.member_id === assigneeId) || {}
    },

    onClickTask (task) {
      this.$emit('open', task)
    }
  }
}
</script>
