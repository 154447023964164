<template>
  <div class="pages-menu">
    <pages-menu-section/>

    <pages-menu-section
      v-for="project in projects"
      :key="project.code"
      :project="project"
    />
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import PagesMenuSection from '@/components/pages/PagesMenuSection';

  export default {
    name: 'PagesMenu',

    components: {PagesMenuSection },

    computed: {
      ...mapGetters(['projects']),
      ...mapGetters('pages', ['mainPagesByProject', 'mainPages']),
    },

    methods: {
      ...mapActions('pages', ['fetchMainPages', 'setPagePreData']),

      onCreatePage (query) {
        this.$router.push({ name: 'pages.create', query })
      }
    },

    async created () {
      await this.fetchMainPages()
    }
  }
</script>
<style lang="sass">
.pages-menu
  padding: 16px 4px
  flex: 1
  overflow: auto
</style>
